/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useNavigate, useParams } from "react-router-dom";
import Select, { createFilter } from 'react-select';
import DatePicker from "react-datepicker";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import back_arrow from '../../assets/icons/back_arrow.svg';
import brdcrm_nav from '../../assets/icons/brdcrm_nav.svg';
import info_tooltip from '../../assets/icons/Info-tooltip.svg';
import OpenAIComponent from "../../components/OpenAI/OpenAIComponent";

import axiosInstance from '../../helpers/axiosConfig';
import WorkExperience from './personalProfile/modals/WorkExperience';
import MilitaryService from './personalProfile/modals/MilitaryService';
import Education from './personalProfile/modals/Education';

import { ObjectDataPersonalProfiledata } from './personalProfile/commonFunction';
import { decryptText, driverLicenseValidate, encryptText, ssnValidate, contactNumberValidate, zipCodeValidator } from '../../helpers/DynamicFunctions';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../_redux/slices';
import Constants from '../../helpers/Constants';
import { AllcheckformatDate } from './personalProfile/DatesCheck';
import { FieldControl, FieldGroup, FormBuilder, Validators } from 'react-reactive-form';
import FormField from '../../components/FormField/FormField';
import SidePanelEmail from '../../components/SidePanel/SidePanelEmail';
import ContactNumberField from '../../components/ContactField/ContactField';
import { staticEthnicityList, staticGenderList, staticRaceList, staticStatesList, staticVerteranList } from '../../helpers/staticContentData';

let initialFormGroup = FormBuilder.group({
    personalResumeID: [0],
    // Personal Information
    First_Name: ["", [Validators.required]],
    Last_Name: [""],
    Social_Security_Number: ["", [Validators.required, ssnValidate]],
    Driver_License_Number: ["", [Validators.required, driverLicenseValidate]],
    Date_of_Birth_Personal_info: ["", [Validators.required]],

    // Contact and Address Information
    Borrower_Contact_Number: ["", [Validators.required, contactNumberValidate]],
    Street_Address: ["", [Validators.required]],
    Street_Address_2: [""],
    City: ["", [Validators.required]],
    State: ["", [Validators.required]],
    ZIP_Code: ["", [Validators.required, zipCodeValidator]],
    Street_Address_two: [""],
    Street_Address_2_two: [""],
    City_two: [""],
    State_two: [""],
    ZIP_Code_two: [""],

    // Citizenship Information
    Citizenship_Status: [""],
    Country_of_Citizenship_Lawful_Permanent: [""],
    Type_of_Visa: [""],
    Registration_Number: [""],
    Country_of_Citizenship_Not_both: [""],
    Birth_City: ["", [Validators.required]],
    Birth_State: ["", [Validators.required]],
    Birth_Country: ["", [Validators.required]],

    // Spouse Information
    Spouse_First_Name: [""],
    Spouse_Last_Name: [""],
    Spouse_Email: [""],
    Spouse_Social_Security_Number: [""],
    Spouse_Phone_Number: [""],

    // Legal and Financial Information
    filed_for_bankruptcy: [""],
    involved_pending_legal_actions: [""],
    involved_pending_legal_actions_Business: [""],
    bankruptcy_: [""],
    owner_more_than_60_days_delinquent: [""],
    owner_more_than_60_days_delinquent_financing_default_currently: [""],
    delinquent_financing_default_loss: [""],
    owner_currently_delinquent: [""],
    owner_file_joint_return: [""],
    owner_presently_incarcerated: [""],

    // Parole, Probation, and Government Service Information
    owner_presently_under_indictment: [""],
    owner_presently_under_indictment_Reason: [""],
    indictment_Position: [""],
    applied_for_government_financing: [""],
    Starting_Date_parole_probation: [""],
    End_Date_parole_probation: [""],
    Starting_Date_US_government: [""],
    End_Date_US_government: [""],

    // Legal Action Information
    Name_Business_Individual: [""],
    Nature_of_Legal_Action: [""],
    applied_for_government_financing_Adverse_Action: [""],

    // Additional Legal and Financial Data
    owner_presently_suspended: [""],
    owner_presently_suspended_charge: [""],
    owner_currently_delinquent_presently_Reason_for_occurrence: [""],
    owner_currently_delinquent_presently_Federal_Department: [""],
    owner_presently_incarcerated_Days_Delinquent: [""],
    owner_presently_incarcerated_Reason_for_delinquency: [""],

    // Demographics
    race: ["", [Validators.required]],
    Veteran: ["", [Validators.required]],
    gender: ["", [Validators.required]],
    typeOfEthnicity: ["", [Validators.required]],

    // Dates Fields
    Date_From_Address_info: ["", [Validators.required]],
    Date_To_Address_info: [""],
    Date_To_Address_info_two: [""],
    Date_From_Address_info_two: [""],
    Date_of_Legal_Action: [""],
    Date_of_Occurrence_violation: [""],
    Date_of_charge_violation: [""],
});

const emailValidator = (control) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!control.value) {
        return null;
    } else if (!emailPattern.test(control.value)) {
        return { invalidEmail: true };
    }
    return null;
};

let tempWOrkExperience = [];
let tempMilitaryService = [];
let tempEducationData = [];

function PersonalProfileDetail() {
    const dispatch = useDispatch()

    const usVeteranRef = useRef(null);
    const genderRef = useRef(null);
    const raceRef = useRef(null);
    const typeOfEthnicityRef = useRef(null);
    const currentAddressStateRef = useRef(null);
    const previousAddressStateRef = useRef(null);
    const bankruptcyTypeRef = useRef(null);

    const [workExperiences, setWorkExperiences] = useState([]);
    const [militaryServices, setMilitaryServices] = useState([]);
    const [educationData, setEducationData] = useState([]);

    const handleEducationUpdate = (data) => {
        tempEducationData = data;
        setEducationData(data);
    };
    const handleWorkExperienceUpdate = (data) => {
        tempWOrkExperience = data;
        setWorkExperiences(data);
    };

    const handleMilitaryServicesUpdate = (services) => {
        tempMilitaryService = services;
        setMilitaryServices(services);
    };

    const [usastate, setUsastates] = useState([])
    const [typeOfEthnicity, setTypeOfEthnicity] = useState([])
    const [gender, setGender] = useState([])
    const [race, setRace] = useState([])
    const [veteran, setVeteran] = useState([])

    const [selectedVeteran, setSelectedVeteran] = useState(null);
    const [selectedRace, setSelectedRace] = useState(null);
    const [selectedTypeOfEthnicity, setSelectedTypeOfEthnicity] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);

    const [selectedCurrentState, setSelectedCurrentState] = useState(null);
    const [selectedPreviousState, setSelectedPreviousState] = useState(null);
    const [selectedInictmentPosition, setSelectedInictmentPosition] = useState(null);

    const [citizenshipStatus, setCitizenshipStatus] = useState("");
    const [isLessThan10YearsDate, setIsLessThan10YearsDate] = useState(false);
    const [ownerFileJointReturn, setOwnerFileJointReturn] = useState(false);

    const [priorAddressDate, setPriorAddressDate] = useState({ startDate: null, endDate: null });

    useEffect(() => {
        if (citizenshipStatus === "Lawful_Permanent_Resident_status") {
            initialFormGroup.get('Country_of_Citizenship_Lawful_Permanent').setValidators([Validators.required]);
            initialFormGroup.get('Registration_Number').setValidators([Validators.required]);
            initialFormGroup.get('Country_of_Citizenship_Not_both').clearValidators();
            initialFormGroup.get('Type_of_Visa').clearValidators();
        } else if (citizenshipStatus === "Not_both") {
            initialFormGroup.get('Country_of_Citizenship_Not_both').setValidators([Validators.required]);
            initialFormGroup.get('Type_of_Visa').setValidators([Validators.required]);
            initialFormGroup.get('Country_of_Citizenship_Lawful_Permanent').clearValidators();
            initialFormGroup.get('Registration_Number').clearValidators();
        } else {
            initialFormGroup.get('Country_of_Citizenship_Lawful_Permanent').clearValidators();
            initialFormGroup.get('Registration_Number').clearValidators();
            initialFormGroup.get('Country_of_Citizenship_Not_both').clearValidators();
            initialFormGroup.get('Type_of_Visa').clearValidators();
        }
        initialFormGroup.get('Country_of_Citizenship_Lawful_Permanent').updateValueAndValidity();
        initialFormGroup.get('Registration_Number').updateValueAndValidity();
        initialFormGroup.get('Country_of_Citizenship_Not_both').updateValueAndValidity();
        initialFormGroup.get('Type_of_Visa').updateValueAndValidity();

    }, [citizenshipStatus])

    useEffect(() => {
        if (ownerFileJointReturn) {
            initialFormGroup.get('Spouse_First_Name').setValidators([Validators.required]);
            initialFormGroup.get('Spouse_Last_Name').setValidators([Validators.required]);
            initialFormGroup.get('Spouse_Email').setValidators([Validators.required, emailValidator]);
            initialFormGroup.get('Spouse_Social_Security_Number').setValidators([ssnValidate]);
            initialFormGroup.get('Spouse_Phone_Number').setValidators([Validators.required, contactNumberValidate]);
        } else {
            initialFormGroup.get('Spouse_First_Name').clearValidators();
            initialFormGroup.get('Spouse_Last_Name').clearValidators();
            initialFormGroup.get('Spouse_Email').clearValidators();
            initialFormGroup.get('Spouse_Social_Security_Number').clearValidators();
            initialFormGroup.get('Spouse_Phone_Number').clearValidators();
        }
        initialFormGroup.get('Spouse_First_Name').updateValueAndValidity();
        initialFormGroup.get('Spouse_Last_Name').updateValueAndValidity();
        initialFormGroup.get('Spouse_Email').updateValueAndValidity();
        initialFormGroup.get('Spouse_Social_Security_Number').updateValueAndValidity();
        initialFormGroup.get('Spouse_Phone_Number').updateValueAndValidity();

    }, [ownerFileJointReturn])


    useEffect(() => {
        if (isLessThan10YearsDate) {
            initialFormGroup.get('Street_Address_two').setValidators([Validators.required]);
            initialFormGroup.get('City_two').setValidators([Validators.required]);
            initialFormGroup.get('State_two').setValidators([Validators.required]);
            initialFormGroup.get('ZIP_Code_two').setValidators([Validators.required, zipCodeValidator]);
            initialFormGroup.get('Date_To_Address_info_two').setValidators([Validators.required]);
            initialFormGroup.get('Date_From_Address_info_two').setValidators([Validators.required]);
        } else {
            initialFormGroup.get('Street_Address_two').clearValidators();
            initialFormGroup.get('City_two').clearValidators();
            initialFormGroup.get('State_two').clearValidators();
            initialFormGroup.get('ZIP_Code_two').clearValidators();
            initialFormGroup.get('Date_To_Address_info_two').clearValidators();
            initialFormGroup.get('Date_From_Address_info_two').clearValidators();
        }
        initialFormGroup.get('Street_Address_two').updateValueAndValidity();
        initialFormGroup.get('City_two').updateValueAndValidity();
        initialFormGroup.get('State_two').updateValueAndValidity();
        initialFormGroup.get('ZIP_Code_two').updateValueAndValidity();
        initialFormGroup.get('Date_To_Address_info_two').updateValueAndValidity();
        initialFormGroup.get('Date_From_Address_info_two').updateValueAndValidity();

    }, [isLessThan10YearsDate])

    function markAllAsTouched(formGroup) {
        // Focus on specific refs if they exist
        Object.keys(formGroup.controls).reverse().forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                console.log(controlName);
                control.active = true;
                control.touched = true;

                if (controlName === "usVeteran") usVeteranRef.current.focus();
                if (controlName === "gender") genderRef.current.focus();
                if (controlName === "race") raceRef.current.focus();
                if (controlName === "typeOfEthnicity") typeOfEthnicityRef.current.focus();
                if (controlName === "State") currentAddressStateRef.current.focus();
                if (controlName === "State_two") previousAddressStateRef.current.focus();
                if (controlName === "bankruptcy_") bankruptcyTypeRef.current.focus();

                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    }

    const getMasterArrayList = async () => {
        try {
            setLoading(true);
            setTypeOfEthnicity(staticEthnicityList);
            setRace(staticRaceList);
            setVeteran(staticVerteranList);
            setGender(staticGenderList);
            setUsastates(staticStatesList);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };


    // Meta Title
    useEffect(() => {
        document.title = "Personal Profile Detail - Loan Application";
        getMasterArrayList()
        getInquiries()
    }, []);

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: "start"
    };

    // Preloader Start
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);


    // Preloader End

    const [personalProfileData, setPersonalProfileData] = useState({ loanId: "", ownerId: "" });
    const [applicationNumber, setApplicationNumber] = useState("")
    const [lastSavedFormDataRef, setLastSavedFormDataRef] = useState({});
    const [isGuestType, setIsGuestType] = useState(false);

    // Function to compare current formData with lastSavedFormData
    const combineLatestData = (formData) => {
        return {
            ...formData,
            usVeteran: selectedVeteran,
            race: selectedRace,
            gender: selectedGender,
            typeOfEthnicity: selectedTypeOfEthnicity,
            businessState: selectedCurrentState,
            pBusinessState: selectedPreviousState,
            indictmentParoleProbation: selectedInictmentPosition,
            isLessThan10YearsDate: isLessThan10YearsDate,
            ownerFileJointReturn: ownerFileJointReturn,
            educationData: educationData,
            workExperiences: workExperiences,
            militaryServices: militaryServices
        }
    }
    const hasFormDataChanged = () => {
        const a = combineLatestData(initialFormGroup.value)
        const b = lastSavedFormDataRef;

        let checkForChangeFlag = false
        Object.keys(a).forEach(key => {
            if (a[key] !== b[key]) {
                console.log(key, a[key], b[key])
                checkForChangeFlag = true;
            }
        })
        return checkForChangeFlag;
    };

    const params = useParams()

    const getInquiries = async () => {
        try {
            setLoading(true);
            let oid = decryptText(params.loanId);
            const [loanId, ownerId, ApplicationNumber, type] = oid.split('&')
            setIsGuestType(type === "Guest");
            setApplicationNumber(ApplicationNumber)
            setPersonalProfileData({ loanId, ownerId });

            if (loanId) {
                initialFormGroup.reset();
                let apiUrl = type === "Guest" ? `/api/LoanApplication/Guest_GetPersonalResumeByOwnerID` : `/api/LoanApplication/GetPersonalResumeByOwnerID`;
                let queryParams = `?LoanId=${loanId}&OwnerId=${ownerId}&SessionId=${localStorage.getItem('sessionId')}`;

                let response = await axiosInstance.get(`${apiUrl}${queryParams}`)
                let newdata = await response.data
                if (newdata.status === 200) {
                    let data = newdata.resultObject

                    initialFormGroup.patchValue({
                        personalResumeID: data.personalResumeID || 0,
                        // Personal Information
                        First_Name: data.name,
                        Last_Name: data.lastName,
                        Social_Security_Number: data.socialSecurityNumber,
                        Driver_License_Number: data.driverLicenseNumber,

                        // Contact and Address Information
                        Borrower_Contact_Number: data.borrowerContactNumber,
                        Street_Address: data.businessAddressLine1,
                        Street_Address_2: data.businessAddressLine2,
                        City: data.businessCity,
                        State: data.businessState,
                        ZIP_Code: data.businessZIPCode,
                        ZIP_Code_two: data.pBusinessZIPCode,
                        Street_Address_two: data.pBusinessAddressLine1,
                        Street_Address_2_two: data.pBusinessAddressLine2,
                        City_two: data.pBusinessCity,
                        State_two: data.pBusinessState,

                        // Citizenship Information
                        Citizenship_Status: data.isNotLawfullResident ? "Not_both" : data.isLawfullResident ? "Lawful_Permanent_Resident_status" : "US_Citizen",
                        Country_of_Citizenship_Lawful_Permanent: data.noUSCitizen_Country,
                        Registration_Number: data.usCitizenDetail,
                        Type_of_Visa: data.typeofViza,
                        Country_of_Citizenship_Not_both: data.noLawfullUSCitizen_Country,

                        // Spouse Information
                        Spouse_First_Name: data.spouseName,
                        Spouse_Last_Name: data.spouseLastName,
                        Spouse_Email: data.spouseEmailId,
                        Spouse_Social_Security_Number: data.spouseSocialSecurityNumber,
                        Spouse_Phone_Number: data.spousePhoneNo,

                        // Legal and Financial Information
                        filed_for_bankruptcy: data.filed_for_bankruptcy,
                        involved_pending_legal_actions: data.isInvolvedInLegalAction,
                        involved_pending_legal_actions_Business: data.isLegalActionPersonal,
                        bankruptcy_: data.everFiledForbankruptcy,
                        owner_more_than_60_days_delinquent: data.isControlledByGuarantedLoan,
                        owner_more_than_60_days_delinquent_financing_default_currently: data.isGuarantedLoanCurrentDelinquent,
                        delinquent_financing_default_loss: data.isGuarantedLoanDefaultOrCauseLoss,
                        owner_currently_delinquent: data.presentlyDebarred,
                        owner_presently_incarcerated: data.moreOwnerOfBusiness,
                        owner_file_joint_return: data.isJointReturn,

                        // Parole, Probation, and Government Service Information
                        owner_presently_under_indictment: data.isUnderIndictment,
                        indictment_Position: data.indictmentParoleProbation,
                        applied_for_government_financing: data.isVehicleViolation,

                        // Legal Action Information
                        Name_Business_Individual: data.legalActionBusinessPerson,
                        Nature_of_Legal_Action: data.legalActionNature,
                        applied_for_government_financing_Adverse_Action: data.adverseActionReason,

                        // Personal Identification Information
                        Birth_City: data.city,
                        Birth_State: data.state,
                        Birth_Country: data.country,

                        // Additional Legal and Financial Data
                        owner_presently_suspended_charge: data.natureOfCharge,
                        owner_currently_delinquent_presently_Reason_for_occurrence: data.occuranceReason,
                        owner_currently_delinquent_presently_Federal_Department: data.fedralDeptOrAgencyName,
                        owner_presently_incarcerated_Days_Delinquent: data.daysDeliquent,
                        owner_presently_incarcerated_Reason_for_delinquency: data.reasonForDeliquency,

                        // Demographics
                        race: data.race,
                        Veteran: data.usVeteran,
                        gender: data.gender,
                        typeOfEthnicity: data.ethnicity,

                        // Dates filleds
                        Date_From_Address_info: AllcheckformatDate(data.livedFrom),
                        Date_To_Address_info: AllcheckformatDate(data.livedTo),
                        Date_To_Address_info_two: AllcheckformatDate(data.p_LivedTo),
                        Date_From_Address_info_two: AllcheckformatDate(data.p_LivedFrom),
                        Starting_Date_parole_probation: AllcheckformatDate(data.underIndictmentStartDate),
                        Date_of_Birth_Personal_info: AllcheckformatDate(data.dateofBirth),
                        End_Date_US_government: AllcheckformatDate(data.usGovEmployeeEndDate),
                        Date_of_Legal_Action: AllcheckformatDate(data.legalActionDate),
                        Date_of_Occurrence_violation: AllcheckformatDate(data.occuranceProbationPastDate),
                        Date_of_charge_violation: AllcheckformatDate(data.dateOfCharge),
                        End_Date_parole_probation: AllcheckformatDate(data.underIndictmentEndDate),
                        Starting_Date_US_government: AllcheckformatDate(data.usGovEmployeeStartDate)
                    });

                    setPriorAddressDate({ startDate: AllcheckformatDate(data.p_LivedFrom), endDate: AllcheckformatDate(data.p_LivedTo) });


                    setSelectedVeteran(data.usVeteran);
                    setSelectedRace(data.race);
                    setSelectedTypeOfEthnicity(data.ethnicity);
                    setSelectedGender(data.gender)

                    setSelectedCurrentState(data.businessState);
                    setSelectedPreviousState(data.pBusinessState);
                    setSelectedInictmentPosition(data.indictmentParoleProbation);
                    setIsLessThan10YearsDate(isLessThan10Years(data.livedFrom));
                    setOwnerFileJointReturn(data.isJointReturn)

                    const updatedData = data.educations?.map(item => {
                        const { from, to, ...rest } = item;
                        return { ...rest, startDate: AllcheckformatDate(from), endDate: AllcheckformatDate(to) };
                    });
                    setEducationData(updatedData)
                    tempEducationData = updatedData;

                    const updatedDataBusiness = data.businessExperiences?.map(item => {
                        const { from, to, ...rest } = item;
                        return { ...rest, startDate: AllcheckformatDate(from), endDate: AllcheckformatDate(to) };
                    });
                    setWorkExperiences(updatedDataBusiness)
                    tempWOrkExperience = updatedDataBusiness;

                    const updatedDatamillitary = data.miltaryservice?.map(item => {
                        const { datesofService, to, ...rest } = item;
                        return { ...rest, startDate: AllcheckformatDate(datesofService), endDate: AllcheckformatDate(to) };
                    });
                    setMilitaryServices(updatedDatamillitary)
                    tempMilitaryService = updatedDatamillitary;


                    let getlastSavedData = {
                        ...initialFormGroup.value,
                        usVeteran: data.usVeteran,
                        race: data.race,
                        ethnicity: data.ethnicity,
                        gender: data.gender,
                        businessState: data.businessState,
                        pBusinessState: data.pBusinessState,
                        indictmentParoleProbation: data.indictmentParoleProbation,
                        isLessThan10YearsDate: isLessThan10Years(data.livedFrom),
                        ownerFileJointReturn: data.isJointReturn,
                        educationData: updatedData,
                        workExperiences: updatedDataBusiness,
                        militaryServices: updatedDatamillitary
                    }
                    setLastSavedFormDataRef(getlastSavedData);
                }
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const [backToLocation, setBackToLocation] = useState("");
    const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
    const PreviousTabPopupClose = () => setPreviousTabPopup(false);

    const navigate = useNavigate();

    const backPreviousTab = (getLocation = "") => {
        if (backToLocation === "loan-applications" || getLocation === "loan-applications") {
            navigate('/loan-application')
        } else {
            navigate(`/application-detail/${encryptText(personalProfileData.loanId)}`, { state: { tabName: 'personal_profile' } });
            setPreviousTabPopup(false);
        }
    }

    const commonDropdownOptions = [
        { value: 'Indictment', label: 'Indictment' },
        { value: 'Parole', label: 'Parole' },
        { value: 'Probation', label: 'Probation' },
    ]

    const handleBackpopup = (whereto) => {
        setBackToLocation(whereto);
        if (hasFormDataChanged()) {
            setPreviousTabPopup(true);
        } else {
            backPreviousTab(whereto)
        }
    };

    const HandleFinalSubmit = async (e) => {
        e.preventDefault();

        initialFormGroup.patchValue({
            Veteran: selectedVeteran,
            race: selectedRace,
            gender: selectedGender,
            typeOfEthnicity: selectedTypeOfEthnicity,
        })
        if (initialFormGroup.status !== "INVALID") {
            // Prepare data for submission
            let data = ObjectDataPersonalProfiledata(initialFormGroup.value);
            data['usVeteran'] = selectedVeteran;
            data['race'] = selectedRace;
            data['gender'] = selectedGender;
            data['ethnicity'] = selectedTypeOfEthnicity;
            data['pBusinessState'] = initialFormGroup.value.State_two;
            data['businessState'] = initialFormGroup.value.State
            data['pBusinessCity'] = initialFormGroup.value.City_two;
            data['pBusinessAddressLine2'] = initialFormGroup.value.Street_Address_2_two;
            data['pBusinessAddressLine1'] = initialFormGroup.value.Street_Address_two;
            data['LivedTo'] = initialFormGroup.value.Date_To_Address_info;
            data['LoanId'] = personalProfileData.loanId;
            data['P_LivedTo'] = initialFormGroup.value.Date_To_Address_info_two;
            data['P_LivedFrom'] = initialFormGroup.value.Date_From_Address_info_two;
            data['PBusinessZIPCode'] = initialFormGroup.value.ZIP_Code_two;
            data['OwnerId'] = personalProfileData.ownerId;
            data['IsCriminalOffense'] = false;

            data['IndictmentParoleProbation'] = initialFormGroup.value.bankruptcy_ ? initialFormGroup.value.indictment_Position : "";
            data['UnderIndictmentStartDate'] = initialFormGroup.value.bankruptcy_ ? initialFormGroup.value.Starting_Date_parole_probation : "";
            data['UnderIndictmentEndDate'] = initialFormGroup.value.bankruptcy_ ? initialFormGroup.value.End_Date_parole_probation : "";

            data['OccuranceReason'] = initialFormGroup.value.owner_more_than_60_days_delinquent_financing_default_currently ? initialFormGroup.value.owner_currently_delinquent_presently_Reason_for_occurrence : "";

            data['LegalActionDate'] = initialFormGroup.value.involved_pending_legal_actions ? initialFormGroup.value.Date_of_Legal_Action : "";
            data['LegalActionBusinessPerson'] = initialFormGroup.value.involved_pending_legal_actions ? initialFormGroup.value.Name_Business_Individual : "";
            data['LegalActionNature'] = initialFormGroup.value.involved_pending_legal_actions ? initialFormGroup.value.Nature_of_Legal_Action : "";

            data['ReasonForDeliquency'] = initialFormGroup.value.owner_presently_incarcerated ? initialFormGroup.value.owner_presently_incarcerated_Reason_for_delinquency : "";
            data['AdverseActionReason'] = initialFormGroup.value.applied_for_government_financing ? initialFormGroup.value.applied_for_government_financing_Adverse_Action : "";

            const checkDateAndFormate = (date) => {
                return date ? moment(date).format('YYYY-MM-DD') : "";
            }
            data.miltaryservice = tempMilitaryService.length ? tempMilitaryService?.map(item => {
                const { startDate, endDate, ...rest } = item;
                return { ...rest, datesofService: checkDateAndFormate(startDate), to: checkDateAndFormate(endDate) };
            }) : []

            data.educations = tempEducationData.length ? tempEducationData?.map(item => {
                const { startDate, endDate, ...rest } = item;
                return { ...rest, from: checkDateAndFormate(startDate), to: checkDateAndFormate(endDate) };
            }) : []

            data.businessExperiences = tempWOrkExperience.length ? tempWOrkExperience?.map(item => {
                const { startDate, endDate, ...rest } = item;
                return { ...rest, from: checkDateAndFormate(startDate), to: endDate ? checkDateAndFormate(endDate) : "" };
            }) : [];


            data.ModifiedBy = JSON.parse(localStorage.getItem("user"))?.id || 0
            try {
                setLoading(true)
                let apiURl = isGuestType ? `Guest_InsertUpdatePersonalResume` : `InsertUpdatePersonalResume`;
                const response = await axiosInstance.post(`/api/LoanApplication/${apiURl}`, data, {
                    params: { SessionId: localStorage.getItem('sessionId') },
                    headers: { 'Content-Type': 'application/json' }
                });

                if (response.data.status === 200) {
                    setLastSavedFormDataRef(combineLatestData(initialFormGroup.value));
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    backPreviousTab()
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } else {
            markAllAsTouched(initialFormGroup);
        }
    };
    function isLessThan10Years(dateFromStr) {
        let dateTo = new Date();
        let dateFrom = new Date(dateFromStr);
        let diffInMs = dateTo - dateFrom;
        let diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365.25);
        return diffInYears < 10;
    }

    return (
        <>
            {/* SidePanel */}
            {isGuestType ? <SidePanelEmail /> : <SidePanel />}

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="dashboard-wrap application-detail bg-white pb-5">
                    {/* <!-- Personal Profile header sec start --> */}
                    <div className="padding-reg">
                        {/* <!-- Personal Profile breadcrumb start --> */}
                        <div className="lm-breadcrumb d-flex">

                            <a onClick={() => { handleBackpopup('loan-applications') }} className="back-btn mr-2 cursor-pointer"><img src={back_arrow} alt="" /></a>
                            <span onClick={() => { handleBackpopup('loan-applications') }} className="text-light mr-1 cursor-pointer">Loan Applications</span>
                            <img src={brdcrm_nav} alt="" />
                            <span className="text-dark ml-1">{applicationNumber}</span>
                        </div>
                        <h1 className="appliaction-no my-3">{initialFormGroup.value.First_Name} {initialFormGroup.value.Last_Name}</h1>


                        {/* Personal Information section start */}
                        <FieldGroup
                            control={initialFormGroup}
                            render={({ get, invalid }) => (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="lm-card-heading">Personal Information</h3>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    label="First Name"
                                                    id="First_Name"
                                                    name="First_Name"
                                                    placeholder={"Enter your first name"}
                                                    required={true}
                                                    disabled={true}
                                                    errorMessage="First Name is required"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    label="Last Name"
                                                    id="Last_Name"
                                                    name="Last_Name"
                                                    placeholder={"Enter your last name"}
                                                    required={true}
                                                    disabled={true}
                                                    errorMessage="Last Name is required"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FieldControl
                                                name="Social_Security_Number"
                                                render={({ handler, touched, hasError, meta }) => {
                                                    const handlerProps = handler();

                                                    const standardizeSSN = (e) => {
                                                        let ssnValue = e.target.value.replace(/\D/g, '');
                                                        let cursorPosition = e.target.selectionStart;

                                                        if (e.nativeEvent.inputType === "deleteContentBackward") {
                                                            if (handlerProps.onChange) {
                                                                handlerProps.onChange(e);
                                                            }
                                                            return;
                                                        }

                                                        if (ssnValue.length <= 9) {
                                                            const ssnFormatted = ssnValue.replace(/^(\d{3})(\d{2})(\d{0,4})$/, "$1-$2-$3");
                                                            e.target.value = ssnFormatted;

                                                            if (ssnValue.length > 3 && ssnValue.length <= 5) {
                                                                cursorPosition += 1;
                                                            } else if (ssnValue.length > 5) {
                                                                cursorPosition += 2;
                                                            }
                                                        }

                                                        e.target.setSelectionRange(cursorPosition, cursorPosition);

                                                        if (handlerProps.onChange) {
                                                            handlerProps.onChange(e.target.value); // Pass the formatted SSN value
                                                        }
                                                    };

                                                    return (
                                                        <div className="form-group">
                                                            <label htmlFor="Social_Security_Number">Social Security Number<span className="asterisk">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Social_Security_Number"
                                                                autoComplete="off"
                                                                placeholder="123-45-6789"
                                                                {...handlerProps}
                                                                onChange={standardizeSSN}
                                                                maxlength={11}  // 9 digits + 2 hyphens
                                                            />

                                                            {/* Error messages below */}
                                                            {touched && hasError("required") && <><span className="error-message">Social Security number is required</span><br /></>}
                                                            {touched && hasError("ssnIssue") && <><span className="error-message">Enter a valid social security number</span><br /></>}
                                                        </div>
                                                    );
                                                }}
                                                meta={{ placeholder: "123-45-6789" }}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FieldControl
                                                name="Driver_License_Number"
                                                validators={driverLicenseValidate}
                                                render={({ handler, touched, hasError }) => (
                                                    <div className="form-group">
                                                        <label htmlFor="Driver_License_Number">Driver's License Number<span className="asterisk">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="Driver_License_Number"
                                                            autoComplete="off"
                                                            placeholder="Enter your driver's license number"
                                                            {...handler()}
                                                        />

                                                        {/* Error messages */}
                                                        {touched && hasError("required") && <span className="error-message">Driver's License is required</span>}
                                                        {touched && hasError("driverLicenseIssue") && <span className="error-message">Enter a valid Driver's License (6-20 characters)</span>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FieldControl
                                                name="Date_of_Birth_Personal_info"
                                                render={({ handler, touched, hasError, meta }) => (
                                                    <div className="form-group input-date">
                                                        <label htmlFor="Date_of_Birth_Personal_info">Date of Birth<span className="asterisk">*</span></label>
                                                        <DatePicker
                                                            showIcon
                                                            toggleCalendarOnIconClick
                                                            id='Date_of_Birth_Personal_info'
                                                            selected={initialFormGroup.value.Date_of_Birth_Personal_info ? moment(initialFormGroup.value.Date_of_Birth_Personal_info, 'YYYY-MM-DD').toDate() : null}  // Parse initial date without timezone issues
                                                            isClearable={initialFormGroup.value.Date_of_Birth_Personal_info}
                                                            dateFormat="MMM d, yyyy"
                                                            placeholderText="Month DD, YYYY"
                                                            yearDropdownItemNumber={100}
                                                            scrollableYearDropdown
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            required={true}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    handler().onChange(moment(date).format('YYYY-MM-DD'));
                                                                } else {
                                                                    handler().onChange('');
                                                                }
                                                            }}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        />
                                                        <span className="error-message">{hasError("required") && `Date of Birth is required`} </span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <ContactNumberField
                                                name="Borrower_Contact_Number"
                                                label="Borrower Contact Number"
                                                id="Borrower_Contact_Number"
                                                required={true}
                                                errorMessage="Please enter a valid contact number"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <FieldControl
                                                name="Citizenship_Status"
                                                render={({ handler, touched, hasError, meta }) => (
                                                    <div className="form-group">
                                                        <div className="label pb-1">Citizenship Status</div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Citizenship_Status" id="US_Citizen" checked={initialFormGroup.value.Citizenship_Status === "US_Citizen"} value="US_Citizen"
                                                                onClick={(e) => {
                                                                    setCitizenshipStatus("US_Citizen");
                                                                    handler().onChange("US_Citizen")
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="US_Citizen">U.S. Citizen</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Citizenship_Status" id="Lawful_Permanent_Resident_status" value="Lawful_Permanent_Resident_status" checked={initialFormGroup.value.Citizenship_Status === "Lawful_Permanent_Resident_status"}
                                                                onClick={(e) => {
                                                                    setCitizenshipStatus("Lawful_Permanent_Resident_status");
                                                                    handler().onChange("Lawful_Permanent_Resident_status")
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="Lawful_Permanent_Resident_status">Lawful Permanent Resident status</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Citizenship_Status" id="Not_both" value="Not_both" checked={initialFormGroup.value.Citizenship_Status === "Not_both"}
                                                                onClick={(e) => {
                                                                    setCitizenshipStatus("Not_both");
                                                                    handler().onChange("Not_both")
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="Not_both">Not a U.S. Citizen nor Lawful Permanent Resident</label>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <div className='row'>
                                                {
                                                    initialFormGroup.value.Citizenship_Status === "Lawful_Permanent_Resident_status" && (
                                                        <div className="col-12">
                                                            <div className='row'>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            label="Registration Number"
                                                                            id="Registration_Number"
                                                                            name="Registration_Number"
                                                                            placeholder="Enter a Registration Numbe"
                                                                            required={true}
                                                                            errorMessage="Registration Number is required"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            label="Country of Citizenship"
                                                                            id="Country_of_Citizenship_Lawful_Permanent"
                                                                            name="Country_of_Citizenship_Lawful_Permanent"
                                                                            placeholder={"Enter your country of citizenship"}
                                                                            required={true}
                                                                            errorMessage={"Country of Citizenship is required"}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    initialFormGroup.value.Citizenship_Status === "Not_both" && (
                                                        <div className="col-12">
                                                            <div className='row'>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            label="Type of Visa"
                                                                            id="Type_of_Visa"
                                                                            name="Type_of_Visa"
                                                                            placeholder={"Enter your type of visa"}
                                                                            required={true}
                                                                            errorMessage={"Type of Visa is required"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            label="Country of Citizenship"
                                                                            id="Country_of_Citizenship_Not_both"
                                                                            name="Country_of_Citizenship_Not_both"
                                                                            placeholder={"Enter your country of citizenship"}
                                                                            required={true}
                                                                            errorMessage={"Country of Citizenship is required"}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <div className="col-12">
                                                    <div className='row'>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group">
                                                                <FormField
                                                                    label="Birth City"
                                                                    id="Birth_City"
                                                                    name="Birth_City"
                                                                    required={true}
                                                                    placeholder={"Enter your birth city"}
                                                                    errorMessage={"Birth City is required"}
                                                                    onlyCharacters={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group">
                                                                <FormField
                                                                    label="Birth State"
                                                                    id="Birth_State"
                                                                    name="Birth_State"
                                                                    required={true}
                                                                    placeholder={"Enter your birth state"}
                                                                    errorMessage={"Birth State is required"}
                                                                    onlyCharacters={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <div className="form-group">
                                                                <FormField
                                                                    label="Birth Country"
                                                                    id="Birth_Country"
                                                                    name="Birth_Country"
                                                                    required={true}
                                                                    placeholder={"Enter your birth country"}
                                                                    errorMessage={"Birth Country is required"}
                                                                    onlyCharacters={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className='row'>
                                                        <div className="col-md-4 col-lg-3">
                                                            <FieldControl
                                                                name="Veteran"
                                                                render={({ handler, touched, hasError, meta }) => (
                                                                    <div className="form-group">
                                                                        <label htmlFor="Veteran">U.S. Veteran<span className="asterisk">*</span></label>
                                                                        <Select
                                                                            ref={usVeteranRef}
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            id="Veteran"
                                                                            name="Veteran"
                                                                            options={veteran}
                                                                            required={true}
                                                                            value={veteran.find(option => option.value === selectedVeteran)}
                                                                            onChange={(selectedOption) => {
                                                                                setSelectedVeteran(selectedOption.value);
                                                                                handler().onChange(selectedOption.value);
                                                                            }}
                                                                        />
                                                                        <span className="error-message">{touched && hasError("required") && `Veteran is required`} </span>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <FieldControl
                                                                name="gender"
                                                                render={({ handler, touched, hasError, meta }) => (
                                                                    <div className="form-group">
                                                                        <label htmlFor="gender">Gender<span className="asterisk">*</span></label>
                                                                        <Select
                                                                            ref={genderRef}
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            id="gender"
                                                                            name="gender"
                                                                            options={gender}
                                                                            required={true}
                                                                            value={gender.find(option => option.value === selectedGender)}
                                                                            onChange={(selectedOption) => {
                                                                                setSelectedGender(selectedOption.value);
                                                                                handler().onChange(selectedOption.value);
                                                                            }}
                                                                        />
                                                                        <span className="error-message">{touched && hasError("required") && `Gender is required`} </span>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <FieldControl
                                                                name="race"
                                                                render={({ handler, touched, hasError, meta }) => (
                                                                    <div className="form-group">
                                                                        <label htmlFor="race">Race<span className="asterisk">*</span></label>
                                                                        <Select
                                                                            ref={raceRef}
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            id="race"
                                                                            name="race"
                                                                            options={race}
                                                                            required={true}
                                                                            value={race.find(option => option.value === selectedRace)}
                                                                            onChange={(selectedOption) => {
                                                                                setSelectedRace(selectedOption.value);
                                                                                handler().onChange(selectedOption.value);
                                                                            }}
                                                                        />
                                                                        <span className="error-message">{touched && hasError("required") && `Race is required`} </span>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">
                                                            <FieldControl
                                                                name="typeOfEthnicity"
                                                                render={({ handler, touched, hasError, meta }) => (
                                                                    <div className="form-group">
                                                                        <label htmlFor="typeOfEthnicity">Ethnicity<span className="asterisk">*</span></label>
                                                                        <Select
                                                                            ref={typeOfEthnicityRef}
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            id="typeOfEthnicity"
                                                                            name="typeOfEthnicity"
                                                                            options={typeOfEthnicity}
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            required={true}
                                                                            value={typeOfEthnicity.find(option => option.value === selectedTypeOfEthnicity)}
                                                                            onChange={(selectedOption) => {
                                                                                setSelectedTypeOfEthnicity(selectedOption.value);
                                                                                handler().onChange(selectedOption.value);
                                                                            }}
                                                                        />
                                                                        <span className="error-message">{touched && hasError("required") && `Ethnicity is required`} </span>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        {/* Address Section start */}

                                        <div className="col-12 label text-dark mb-2"><b>ADDRESS</b></div>
                                        <div className="col-12 label text-dark mb-2"><b>Current Address </b></div>

                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    label="Street Address"
                                                    id="Street_Address"
                                                    name="Street_Address"
                                                    required={true}
                                                    placeholder={"Enter your street address"}
                                                    errorMessage="Street Address is required"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    label="Street Address 2"
                                                    id="Street_Address_2"
                                                    name="Street_Address_2"
                                                    placeholder={"Enter your street address 2"}
                                                    errorMessage="Street Address 2 is required"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    label="City"
                                                    id="City"
                                                    name="City"
                                                    required={true}
                                                    placeholder={"Enter your city"}
                                                    errorMessage="City is required"
                                                    onlyCharacters={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <FieldControl
                                                name="State"
                                                render={({ handler, touched, hasError, meta }) => (
                                                    <div className="form-group">
                                                        <label htmlFor="State">State<span className="asterisk">*</span></label>
                                                        <Select
                                                            ref={currentAddressStateRef}
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            id="State"
                                                            name="State"
                                                            placeholder="Select"
                                                            options={usastate}
                                                            filterOption={createFilter(filterConfig)}
                                                            value={usastate.find(option => option.value === selectedCurrentState)}
                                                            onChange={(selectedOption) => {
                                                                setSelectedCurrentState(selectedOption.value);
                                                                handler().onChange(selectedOption.value);
                                                            }}
                                                        />
                                                        <span className="error-message">{touched && hasError("required") && `State is required`} </span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-4 col-lg-3">
                                            <div className="form-group">
                                                <FormField
                                                    label="ZIP Code"
                                                    id="ZIP_Code"
                                                    name="ZIP_Code"
                                                    required={true}
                                                    maxlength={5}
                                                    patternRequired={true}
                                                    placeholder={"Enter your ZIP code"}
                                                    errorMessage="ZIP Code is required"
                                                    onlyNumbers={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-lg-3">
                                            <FieldControl
                                                name="Date_From_Address_info"
                                                render={({ handler, touched, hasError, meta }) => (
                                                    <div className="form-group input-date">
                                                        <label htmlFor="Date_From_To">Date From<span className="asterisk">*</span>
                                                            <span className="lm-tooltip ml-1"
                                                                data-tooltip="A prior address is required if the owner has lived in their current address for less than ten years."
                                                                data-tooltip-pos="up" data-tooltip-length="cutsom"> <img src={info_tooltip} alt="" />
                                                            </span>
                                                        </label>
                                                        <DatePicker
                                                            showIcon
                                                            toggleCalendarOnIconClick
                                                            id="Date_From_Address_info"
                                                            selected={initialFormGroup.value.Date_From_Address_info ? moment(initialFormGroup.value.Date_From_Address_info, 'YYYY-MM-DD').toDate() : null}  // Parse initial date without timezone issues  // Parse initial date
                                                            isClearable={initialFormGroup.value.Date_From_Address_info}
                                                            dateFormat="MMM, yyyy"
                                                            showMonthYearPicker
                                                            placeholderText="Month, YYYY"
                                                            yearDropdownItemNumber={100}
                                                            scrollableYearDropdown
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    setIsLessThan10YearsDate(isLessThan10Years(date)); // Update the state if a date is selected
                                                                    handler().onChange(moment(date).format('YYYY-MM-DD'));
                                                                } else {
                                                                    setIsLessThan10YearsDate(false); // Set to false when cleared
                                                                    handler().onChange(''); // Handle cleared date
                                                                }
                                                            }}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        />

                                                        <span className="error-message">{touched && hasError("required") && `Date from is required`} </span>
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        {/* <!-- lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!-- lm common divider to break the section  --> */}

                                        {isLessThan10YearsDate &&
                                            <>
                                                <div className="col-12 label text-dark mb-2"><b>Prior Address 1 </b></div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="Street Address"
                                                            id="Street_Address_two"
                                                            name="Street_Address_two"
                                                            required={true}
                                                            placeholder={"Enter your street address"}
                                                            errorMessage="Street Address is required"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="Street Address 2"
                                                            id="Street_Address_2_two"
                                                            name="Street_Address_2_two"
                                                            placeholder={"Enter your street address 2"}
                                                            errorMessage="Street Address 2 is required"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="City"
                                                            id="City_two"
                                                            name="City_two"
                                                            required={true}
                                                            placeholder={"Enter your city"}
                                                            errorMessage="City is required"
                                                            onlyCharacters={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <FieldControl
                                                        name="State_two"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <label htmlFor="State_two">State<span className="asterisk">*</span></label>
                                                                <Select
                                                                    ref={previousAddressStateRef}
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{
                                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                    }}
                                                                    id="State_two"
                                                                    name="State_two"
                                                                    options={usastate}
                                                                    filterOption={createFilter(filterConfig)}
                                                                    value={usastate.find(option => option.value === selectedPreviousState)}
                                                                    onChange={(selectedOption) => {
                                                                        setSelectedPreviousState(selectedOption.value);
                                                                        handler().onChange(selectedOption.value);
                                                                    }}
                                                                />
                                                                <span className="error-message">{touched && hasError("required") && `State is required`} </span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="ZIP Code"
                                                            id="ZIP_Code_two"
                                                            name="ZIP_Code_two"
                                                            required={true}
                                                            maxlength={5}
                                                            patternRequired={true}
                                                            placeholder={"Enter your ZIP code"}
                                                            errorMessage="ZIP Code is required"
                                                            onlyNumbers={true}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group input-date">
                                                        <label htmlFor="Date_From_To">Date From/To<span className="asterisk">*</span>
                                                            <span className="lm-tooltip ml-1"
                                                                data-tooltip="Owener's address over the prior ten years is required to secure financing."
                                                                data-tooltip-pos="up" data-tooltip-length="cutsom"> <img src={info_tooltip} alt="" />
                                                            </span>
                                                        </label>
                                                        <div className='d-flex'>
                                                            <DatePicker
                                                                showIcon
                                                                toggleCalendarOnIconClick
                                                                selectsRange={true}
                                                                selected={priorAddressDate.startDate}
                                                                startDate={priorAddressDate.startDate}
                                                                endDate={priorAddressDate.endDate}
                                                                monthsShown={2}
                                                                isClearable={true}
                                                                dateFormat="MMM, yyyy"
                                                                className="d-flex"
                                                                id="Date_From_Address_info_two"
                                                                placeholderText="Month, YYYY - Month, YYYY"
                                                                yearDropdownItemNumber={100}
                                                                scrollableYearDropdown
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                onChange={(date) => {
                                                                    const [startDate, endDate] = date;
                                                                    setPriorAddressDate({ startDate, endDate });
                                                                    initialFormGroup.patchValue({ Date_From_Address_info_two: startDate ? moment(startDate).format('YYYY-MM-DD') : '' });
                                                                    initialFormGroup.patchValue({ Date_To_Address_info_two: endDate ? moment(endDate).format('YYYY-MM-DD') : '' });
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (!initialFormGroup.value.Date_From_Address_info_two || !initialFormGroup.value.Date_To_Address_info_two) {
                                                                        setPriorAddressDate({ startDate: null, endDate: null });
                                                                        initialFormGroup.patchValue({ Date_From_Address_info_two: '', Date_To_Address_info_two: '' });
                                                                    }
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <!-- lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!-- lm common divider to break the section  --> */}

                                            </>
                                        }

                                        <div className="col-12">
                                            <FieldControl
                                                name="owner_file_joint_return"
                                                render={({ handler, touched, hasError, meta }) => (
                                                    <div className="form-group">
                                                        <div className="label pb-1">Does the owner file a joint return?</div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio"
                                                                name="owner_file_joint_return" id="owner_file_joint_return_Yes" value={true} checked={initialFormGroup.value.owner_file_joint_return === true}
                                                                onClick={(e) => {
                                                                    setOwnerFileJointReturn(true);
                                                                    handler().onChange(true)
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="owner_file_joint_return_Yes">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="owner_file_joint_return" id="owner_file_joint_return_No" value={false} checked={initialFormGroup.value.owner_file_joint_return === false}
                                                                onClick={(e) => {
                                                                    setOwnerFileJointReturn(false);
                                                                    handler().onChange(false)
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="owner_file_joint_return_No">No</label>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>


                                        {initialFormGroup.value.owner_file_joint_return && (
                                            <>
                                                {/* <!-- lm common divider to break the section  --> */}
                                                <div className="lm-divider my-3 mx-3"></div>
                                                {/* <!-- lm common divider to break the section  --> */}
                                                <div className="col-12 label text-dark mb-2"><b>SPOUSE'S INFORMATION</b></div>

                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="Spouse's First Name"
                                                            id="Spouse_First_Name"
                                                            name="Spouse_First_Name"
                                                            placeholder={"Enter your spouse's first name"}
                                                            errorMessage="Spouse's First Name is required"
                                                            required={true}
                                                            onlyCharacters={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="Spouse's Last Name"
                                                            id="Spouse_Last_Name"
                                                            name="Spouse_Last_Name"
                                                            placeholder={"Enter your spouse's last name"}
                                                            errorMessage="Spouse's Last Name is required"
                                                            required={true}
                                                            onlyCharacters={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <FieldControl
                                                        name="Spouse_Social_Security_Number"
                                                        render={({ handler, touched, hasError, meta }) => {
                                                            const handlerProps = handler();
                                                            const standardizeSSN = (e) => {
                                                                let ssnValue = e.target.value.replace(/\D/g, '');
                                                                let cursorPosition = e.target.selectionStart;

                                                                if (e.nativeEvent.inputType === "deleteContentBackward") {
                                                                    if (handlerProps.onChange) {
                                                                        handlerProps.onChange(e);
                                                                    }
                                                                    return;
                                                                }

                                                                if (ssnValue.length <= 9) {
                                                                    const ssnFormatted = ssnValue.replace(/^(\d{3})(\d{2})(\d{0,4})$/, "$1-$2-$3");
                                                                    e.target.value = ssnFormatted;

                                                                    if (ssnValue.length > 3 && ssnValue.length <= 5) {
                                                                        cursorPosition += 1;
                                                                    } else if (ssnValue.length > 5) {
                                                                        cursorPosition += 2;
                                                                    }
                                                                }

                                                                e.target.setSelectionRange(cursorPosition, cursorPosition);

                                                                if (handlerProps.onChange) {
                                                                    handlerProps.onChange(e.target.value); // Pass the formatted SSN value
                                                                }
                                                            };

                                                            return (
                                                                <div className="form-group">
                                                                    <label htmlFor="Spouse_Social_Security_Number">Spouse's Social Security Number</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Spouse_Social_Security_Number"
                                                                        autoComplete="off"
                                                                        placeholder="123-45-6789"
                                                                        {...handlerProps}
                                                                        onChange={standardizeSSN}
                                                                        maxlength={11}  // 9 digits + 2 hyphens
                                                                    />

                                                                    {/* Error messages below */}
                                                                    {touched && hasError("required") && <><span className="error-message">Social Security number is required</span><br /></>}
                                                                    {touched && hasError("ssnIssue") && <><span className="error-message">Enter a valid social security number</span><br /></>}
                                                                </div>
                                                            );
                                                        }}
                                                        meta={{ placeholder: "123-45-6789" }}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <FormField
                                                            label="Spouse's Email"
                                                            id="Spouse_Email"
                                                            name="Spouse_Email"
                                                            placeholder={"Enter your spouse's email"}
                                                            errorMessage="Spouse's Email is required"
                                                            required={true}
                                                            patternRequired={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <ContactNumberField
                                                            name="Spouse_Phone_Number"
                                                            label="Spouse's Phone Number"
                                                            id="Spouse_Phone_Number"
                                                            required={true}
                                                            errorMessage="Please enter a valid phone number"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {/* Address Section start */}

                                        {/* <!-- lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!-- lm common divider to break the section  --> */}

                                        {/* Personal Details Section start */}

                                        <div className="col-12 label text-dark mb-2"><b>PERSONAL DETAILS</b></div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <FieldControl
                                                        name="bankruptcy_"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <div className="label pb-1">Has the owner ever filed for bankruptcy?</div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="bankruptcy_" id="bankruptcy_yes" value={true} checked={initialFormGroup.value.bankruptcy_ === true} onClick={(e) => { handler().onChange(true) }} />
                                                                    <label className="form-check-label" htmlFor="bankruptcy_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="bankruptcy_" id="bankruptcy_no" value={false} checked={initialFormGroup.value.bankruptcy_ === false} onClick={(e) => { handler().onChange(false) }} />
                                                                    <label className="form-check-label" htmlFor="bankruptcy_no">No</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            initialFormGroup.value.bankruptcy_ && (
                                                <>
                                                    <div className="col-md-4 col-lg-3">
                                                        <FieldControl
                                                            name="indictment_Position"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor="owner_presently_under_indictment">Bankruptcy Type </label>
                                                                    <Select
                                                                        ref={bankruptcyTypeRef}
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        id="indictment_Position"
                                                                        name="indictment_Position"
                                                                        options={commonDropdownOptions}
                                                                        value={commonDropdownOptions.find(option => option.value === selectedInictmentPosition)}
                                                                        onChange={(selectedOption) => {
                                                                            setSelectedInictmentPosition(selectedOption.value);
                                                                            handler().onChange(selectedOption.value);
                                                                        }}
                                                                    />
                                                                    <span className="error-message">{touched && hasError("required") && `Bankruptcy type is required`} </span>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-lg-3">
                                                        <FieldControl
                                                            name="Starting_Date_parole_probation"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Date_of_Filing">Date of Filing </label>
                                                                    <DatePicker
                                                                        showIcon
                                                                        toggleCalendarOnIconClick
                                                                        id='Starting_Date_parole_probation'
                                                                        selected={initialFormGroup.value.Starting_Date_parole_probation ? moment(initialFormGroup.value.Starting_Date_parole_probation, 'YYYY-MM-DD').toDate() : null}  // Parse initial date without timezone issues  // Parse initial date
                                                                        isClearable={initialFormGroup.value.Starting_Date_parole_probation}
                                                                        dateFormat="MMM d, yyyy"
                                                                        placeholderText="Month DD, YYYY"
                                                                        yearDropdownItemNumber={100}
                                                                        scrollableYearDropdown
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                handler().onChange(moment(date).format('YYYY-MM-DD'));
                                                                            } else {
                                                                                handler().onChange('');
                                                                            }
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="col-md-4 col-lg-3">
                                                        <FieldControl
                                                            name="End_Date_parole_probation"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Date_of_Discharge">Date of Discharge </label>
                                                                    <DatePicker
                                                                        showIcon
                                                                        toggleCalendarOnIconClick
                                                                        id='End_Date_parole_probation'
                                                                        selected={initialFormGroup.value.End_Date_parole_probation ? moment(initialFormGroup.value.End_Date_parole_probation, 'YYYY-MM-DD').toDate() : null}  // Parse initial date without timezone issues  // Parse initial date
                                                                        isClearable={initialFormGroup.value.End_Date_parole_probation}
                                                                        dateFormat="MMM d, yyyy"
                                                                        placeholderText="Month DD, YYYY"
                                                                        yearDropdownItemNumber={100}
                                                                        scrollableYearDropdown
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                handler().onChange(moment(date).format('YYYY-MM-DD'));
                                                                            } else {
                                                                                handler().onChange('');
                                                                            }
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>

                                                    {/* <div className="col-lg-9">
                                                        <div className="form-group AI_btn_field">
                                                            <FormField
                                                                label="Reason"
                                                                id="filed_for_bankruptcy"
                                                                name="filed_for_bankruptcy"
                                                                placeholder="Enter the Reason"
                                                                errorMessage="Reason is required"
                                                                maxlength={250}
                                                            />
                                                            {
                                                                initialFormGroup.value.filed_for_bankruptcy && (
                                                                    <button type="button" className='lm-ai-btn is_generating' onClick={(e) => handleAIWrite('filed_for_bankruptcy')}>
                                                                        <img src={ai_icon} />
                                                                        AI rewrite
                                                                    </button>
                                                                )
                                                            }
                                                        </div>
                                                    </div> */}
                                                </>
                                            )
                                        }

                                        <div className="col-12 row">
                                            <div className="col-md-6">
                                                <FieldControl
                                                    name="owner_currently_delinquent"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is the owner presently suspended, debarred, proposed for debarment, declared ineligible, or voluntarily excluded from participation in any transaction by any federal department or agency?</div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="owner_currently_delinquent" id="owner_currently_delinquent_Yes" value={true} checked={initialFormGroup.value.owner_currently_delinquent === true} onClick={(e) => { handler().onChange(true) }} />
                                                                <label className="form-check-label" htmlFor="owner_currently_delinquent_Yes">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="owner_currently_delinquent" id="owner_currently_delinquent_No" value={false} checked={initialFormGroup.value.owner_currently_delinquent === false} onClick={(e) => { handler().onChange(false) }} />
                                                                <label className="form-check-label" htmlFor="owner_currently_delinquent_No">No</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <FieldControl
                                                name="owner_presently_under_indictment"
                                                render={({ handler, touched, hasError, meta }) => (
                                                    <div className="form-group">
                                                        <div className="label pb-1">Is the owner currently incarcerated, or under indictment for a felony?</div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="owner_presently_under_indictment" id="owner_presently_under_indictment_Yes" value={true} checked={initialFormGroup.value.owner_presently_under_indictment === true} onClick={(e) => { handler().onChange(true) }} />
                                                            <label className="form-check-label" htmlFor="owner_presently_under_indictment_Yes">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="owner_presently_under_indictment" id="owner_presently_under_indictment_No" value={false} checked={initialFormGroup.value.owner_presently_under_indictment === false} onClick={(e) => { handler().onChange(false) }} />
                                                            <label className="form-check-label" htmlFor="owner_presently_under_indictment_No">No</label>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>


                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!-- lm common divider to break the section  --> */}

                                        {/* Government Financing Section start */}

                                        <div className="col-12 label text-dark mb-2"><b>GOVERNMENT FINANCING</b></div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <FieldControl
                                                        name="owner_more_than_60_days_delinquent"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <div className="label pb-1">Has the owner ever applied for government financing, including student loans and/or a disaster loan, or guaranteed such a loan?</div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="owner_more_than_60_days_delinquent" id="owner_more_than_60_days_delinquent_yes" value={true} checked={initialFormGroup.value.owner_more_than_60_days_delinquent === true} onClick={(e) => { handler().onChange(true) }} />
                                                                    <label className="form-check-label" htmlFor="owner_more_than_60_days_delinquent_yes" >Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="owner_more_than_60_days_delinquent" id="owner_more_than_60_days_delinquent_no" value={false} checked={initialFormGroup.value.owner_more_than_60_days_delinquent === false} onClick={(e) => { handler().onChange(false) }} />
                                                                    <label className="form-check-label" htmlFor="owner_more_than_60_days_delinquent_no" >No</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 row">
                                            <div className="col-md-6">
                                                <FieldControl
                                                    name="owner_more_than_60_days_delinquent_financing_default_currently"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is the owner currently delinquent, currently delinquent on a government loan or have they ever defaulted on any government loan program?
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="owner_more_than_60_days_delinquent_financing_default_currently" id="owner_more_than_60_days_delinquent_financing_default_currently_yes" value={true} checked={initialFormGroup.value.owner_more_than_60_days_delinquent_financing_default_currently === true} onClick={(e) => { handler().onChange(true) }} />
                                                                <label className="form-check-label" htmlFor="owner_more_than_60_days_delinquent_financing_default_currently_yes">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="owner_more_than_60_days_delinquent_financing_default_currently" id="owner_more_than_60_days_delinquent_financing_default_currently_no" value={false} checked={initialFormGroup.value.owner_more_than_60_days_delinquent_financing_default_currently === false} onClick={(e) => { handler().onChange(false) }} />
                                                                <label className="form-check-label" htmlFor="owner_more_than_60_days_delinquent_financing_default_currently_no">No</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            {initialFormGroup.value.owner_more_than_60_days_delinquent_financing_default_currently && (
                                                <div className="col-md-12">
                                                    <div className='row'>
                                                        <div className="col-md-6">
                                                            <div className="form-group AI_btn_field">
                                                                <FormField
                                                                    label="Reason"
                                                                    id="owner_currently_delinquent_presently_Reason_for_occurrence"
                                                                    name="owner_currently_delinquent_presently_Reason_for_occurrence"
                                                                    placeholder={"Enter the Reason"}
                                                                    errorMessage="Reason for Default is required"
                                                                    maxlength={250}
                                                                />
                                                                <OpenAIComponent
                                                                    fieldName="owner_currently_delinquent_presently_Reason_for_occurrence"
                                                                    formGroup={initialFormGroup}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}

                                        <div className="col-12 label text-dark mb-2"><b>LEGAL</b></div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <FieldControl
                                                        name="involved_pending_legal_actions"
                                                        render={({ handler, touched, hasError, meta }) => (
                                                            <div className="form-group">
                                                                <div className="label pb-1">
                                                                    Is the owner involved in any pending legal actions (including divorce)?
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="involved_pending_legal_actions" id="involved_pending_legal_actions_yes" value={true} checked={initialFormGroup.value.involved_pending_legal_actions === true} onClick={(e) => { handler().onChange(true) }} />
                                                                    <label className="form-check-label" htmlFor="involved_pending_legal_actions_yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="involved_pending_legal_actions" id="involved_pending_legal_actions_no" value={false} checked={initialFormGroup.value.involved_pending_legal_actions === false} onClick={(e) => { handler().onChange(false) }} />
                                                                    <label className="form-check-label" htmlFor="involved_pending_legal_actions_no">No</label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            initialFormGroup.value.involved_pending_legal_actions && (
                                                <div className="col-12 row ">
                                                    <div className="col-md-4 col-lg-3">
                                                        <FieldControl
                                                            name="Date_of_Legal_Action"
                                                            render={({ handler, touched, hasError, meta }) => (
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Bankruptcy_Type">Date of Commencement</label>
                                                                    <DatePicker
                                                                        showIcon
                                                                        toggleCalendarOnIconClick
                                                                        id="Date_of_Legal_Action"
                                                                        selected={initialFormGroup.value.Date_of_Legal_Action ? moment(initialFormGroup.value.Date_of_Legal_Action, 'YYYY-MM-DD').toDate() : null}  // Parse initial date without timezone issues  // Parse initial date
                                                                        isClearable={initialFormGroup.value.Date_of_Legal_Action}
                                                                        dateFormat="MMM d, yyyy"
                                                                        placeholderText="Month DD, YYYY"
                                                                        yearDropdownItemNumber={100}
                                                                        scrollableYearDropdown
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                handler().onChange(moment(date).format('YYYY-MM-DD'));
                                                                            } else {
                                                                                handler().onChange('');
                                                                            }
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <FormField
                                                                label="Parties"
                                                                id="Name_Business_Individual"
                                                                name="Name_Business_Individual"
                                                                errorMessage="Parties is required"
                                                                placeholder="Enter the Parties"
                                                                maxlength={100}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 col-lg-6">
                                                        <div className="form-group AI_btn_field">
                                                            <FormField
                                                                label="Reason"
                                                                id="Nature_of_Legal_Action"
                                                                name="Nature_of_Legal_Action"
                                                                placeholder="Enter the Reason"
                                                                errorMessage="Reason is required"
                                                                maxlength={250}
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="Nature_of_Legal_Action"
                                                                formGroup={initialFormGroup}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}


                                        <div className="col-12 row">
                                            <div className="col-lg-12">
                                                <FieldControl
                                                    name="owner_presently_incarcerated"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is the owner more than 60 days delinquent on any obligation to pay child support?</div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="owner_presently_incarcerated" id="owner_presently_incarcerated_Yes" value={true} checked={initialFormGroup.value.owner_presently_incarcerated === true} onClick={(e) => { handler().onChange(true) }} />
                                                                <label className="form-check-label" htmlFor="owner_presently_incarcerated_Yes">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="owner_presently_incarcerated" id="owner_presently_incarcerated_No" value={false} checked={initialFormGroup.value.owner_presently_incarcerated === false} onClick={(e) => { handler().onChange(false) }} />
                                                                <label className="form-check-label" htmlFor="owner_presently_incarcerated_No">No</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                {
                                                    initialFormGroup.value.owner_presently_incarcerated && (
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group AI_btn_field">
                                                                    <FormField
                                                                        label="Explain"
                                                                        id="owner_presently_incarcerated_Reason_for_delinquency"
                                                                        name="owner_presently_incarcerated_Reason_for_delinquency"
                                                                        errorMessage="Explain is required"
                                                                        placeholder="Write a Reason"
                                                                        maxlength={250}
                                                                    />
                                                                    <OpenAIComponent
                                                                        fieldName="owner_presently_incarcerated_Reason_for_delinquency"
                                                                        formGroup={initialFormGroup}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}


                                        <div className="col-12 row">
                                            <div className="col-md-6">
                                                <FieldControl
                                                    name="applied_for_government_financing"
                                                    render={({ handler, touched, hasError, meta }) => (
                                                        <div className="form-group">
                                                            <div className="label pb-1">
                                                                Is the owner currently incarcerated, serving a sentence of imprisonment imposed upon adjudication of guilty, or is under indictment for a felony or any crime involving or relating to financial misconduct or a false statement?</div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="applied_for_government_financing" id="applied_for_government_financing_Yes" value={true} checked={initialFormGroup.value.applied_for_government_financing === true} onClick={(e) => { handler().onChange(true) }} />
                                                                <label className="form-check-label" htmlFor="applied_for_government_financing_Yes">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" name="applied_for_government_financing" id="applied_for_government_financing_No" value={false} checked={initialFormGroup.value.applied_for_government_financing === false} onClick={(e) => { handler().onChange(false) }} />
                                                                <label className="form-check-label" htmlFor="applied_for_government_financing_No">No</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {
                                            initialFormGroup.value.applied_for_government_financing && (
                                                <div className="col-12 row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group AI_btn_field">
                                                            <FormField
                                                                label="Reason"
                                                                id="applied_for_government_financing_Adverse_Action"
                                                                name="applied_for_government_financing_Adverse_Action"
                                                                patternMessage="Enter a reason"
                                                                errorMessage="Reason is required"
                                                                placeholder="Enter the Reason"
                                                                maxlength={250}
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="applied_for_government_financing_Adverse_Action"
                                                                formGroup={initialFormGroup}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }


                                        {/* <!--  lm common divider to break the section  --> */}
                                        <div className="lm-divider my-3 mx-3"></div>
                                        {/* <!--  lm common divider to break the section  --> */}


                                        {/* <!--  service card --> */}
                                        <WorkExperience onUpdate={handleWorkExperienceUpdate} workExperiencesData={workExperiences} />
                                        <Education onUpdate={handleEducationUpdate} neweducationData={educationData} />
                                        <MilitaryService onUpdate={handleMilitaryServicesUpdate} militaryServicesData={militaryServices} />
                                        {/* <!--  service card --> */}

                                    </div>
                                    <div className="sticky-btn-footer d-flex justify-content-end">
                                        <button onClick={() => { handleBackpopup('application-detail') }} type="button" className="lm-button2">Back</button>
                                        <button type="button" onClick={HandleFinalSubmit} className="lm-button1 ml-3">Save</button>
                                    </div>
                                </>
                            )}
                        />
                    </div>
                </div>

                {/* <!--back to Previous Tab Popup / Modal --> */}
                <Modal
                    className='mark-comp-popup-cust-size'
                    show={PreviousTabPopupShow}
                    onHide={() => setPreviousTabPopup(false)}
                    aria-labelledby="PreviousTabPopupLabel"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body signout-modal">
                                <h3 className="pt-4">
                                    Going back will lead to the loss of unsaved data
                                </h3>
                            </div>
                            <div className="modal-footer la-app-modal-footer">
                                <button type="button" className="lm-button2" onClick={PreviousTabPopupClose}>Cancel</button>
                                <button type="button" className="lm-button1" onClick={backPreviousTab}> Confirm</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* <!--back to Previous Tab Popup / Modal --> */}
            </div>)}

        </>
    );
}

export default PersonalProfileDetail;