const isLocal = window.location.href.includes("localhost");

const Constants = {
    ADMINEMAIL: "",
    ADMINPASSWORD: "",
    TOASTERHIDETIME: 4000,

    OPEN_API_KEY: "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",
    ReCAPTCHAKEY: '6LdptBYqAAAAAB5jpM5AjQUzcODXjD9fe3UxjzIj', // production key
    isCheckCaptcha: !isLocal,

    // For Production 
    // BACKEND_URL: "https://api.loanmantra.com",
    // FRONTEND_URL: "https://app.loanmantra.com",
    // IPGEOLOCATION_API_KEY: '3bf6683caa57465685f783df1eba5806',  // Production keys
    // isCheckForLocationAccess: true,

    // For Developemnt
    BACKEND_URL: 'https://stage-api.loanmantra.com',
    FRONTEND_URL: 'https://stage.loanmantra.com',
    IPGEOLOCATION_API_KEY: '7181cf9967944127b0e49d83851565c0',      // Development keys (Manish's account)
    // ReCAPTCHAKEY: '6LfD3PIbAAAAAJs_eEHvoOl75_83eXSqpPSRFJ_u', // dummy test key for local
    isCheckForLocationAccess: false,

    formRecognizerAPIKey: '2n7Yi2CGzCVKglynHi4Hbvj8h48I3hkuNUlNFzx5ihVFFAUUHoZ4JQQJ99BAACYeBjFXJ3w3AAALACOGHBtB',
    geminiAPIKey: 'AIzaSyBjRpr-ks2ALAm7bMvVbNevTXi00W5ku0U',
}

export default Constants;