/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import Modal from 'react-bootstrap/Modal'
import close_popup from '../../assets/icons/close_popup.svg';
import user_avtar_grey from '../../assets/icons/user_avtar_grey.svg';
import edit_pen from "../../assets/icons/edit-pen.svg";
import Info_tooltip from '../../assets/icons/Info-tooltip.svg';
import grid_company_icon from '../../assets/icons/grid_company_icon.svg';
import feild_minus_icon from '../../assets/icons/feild_minus_icon.svg';
import feild_plus_icon from '../../assets/icons/feild_plus_icon.svg';
import pfs_card_cross from '../../assets/icons/pfs_card_cross.svg';
import pfs_card_tick from '../../assets/icons/pfs_card_tick.svg';
import pfs_card_tick_DG from '../../assets/icons/pfs_card_tick_DG.svg';
import lm_edit_icon from '../../assets/icons/lm-edit-icon.svg';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { FieldControl, FieldGroup, FormBuilder, Validators } from 'react-reactive-form';
import axiosInstance from '../../helpers/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import reducersAndActions from '../../_redux/slices';
import Constants from '../../helpers/Constants';
import AmountContainer from '../../components/Amount/AmountContainer';
import FormRadio from '../../reusablecomponets/FormRadio';
import FormField from '../../components/FormField/FormField';
import FormTextarea from '../../reusablecomponets/FormTextArea';
import OpenAIComponent from "../../components/OpenAI/OpenAIComponent";
import { staticCountryList, staticCreditMemoAgent, staticCreditMemoBenchmarkindex, staticCreditMemoCollateralType, staticCreditMemoLoanType, staticCreditMemoPaymentType, staticCreditMemoStrengthWeak, staticStatesList } from '../../helpers/staticContentData';
import { addCheckings, createLstCreditMemoThirdPartyReportList, removeAllCheckings, removeCheckings, returnCreditMemoFormGroupInArray } from '../../helpers/CreditMemoFunctions';
import moment from 'moment';
import { formatNumberWithCommas, zipCodeValidator } from '../../helpers/DynamicFunctions';

const FormArrayComponent = ({ control, render }) => {
    return (
        control.controls.map((ctrl, index) => render(ctrl, index))
    );
};

const checkAndFormatDate = (date) => {
    return date ? moment(date).format('MMM DD, YYYY') : "";
};

const toFixedOrZero = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? '0.00' : number.toFixed(2);
};

const getValue = (value) => {
    if (typeof value === "string") {
        value = value.replace(/,/g, '');
    }
    value = parseFloat(value);
    return isNaN(value) ? 0 : value;
};

let thirdPartyReportFormGroup = FormBuilder.group({
    id: [0],
    creditMemoId: [0],
    reportType: ["", Validators.required],
    reportDate: [""],
    vendorName: ["", Validators.required],
    comment: [""],
    isDeleted: [0],
    index: [0]
})

function CreditMemo({ loanid }) {
    const dispatch = useDispatch()

    const user = useSelector((state) => state.auth.user)
    const staticStatesListOptions = [{ value: "", label: "Select..." }, ...staticStatesList, { value: "Other", label: "Other" }]

    const [memoData, setMemoData] = useState({})
    const [formData, setFormData] = useState(
        FormBuilder.group({
            id: [0],
            loanId: [0, Validators.required],
            loanType: ["", Validators.required],
            sbaFee: [0],
            sbaSmallSize: [0],
            sbaCredit: [false],
            eligibilitySmallSize: [0],
            eligibilityCredit: [false],
            interestType: [""],
            interestBenchmarkIndex: [0],
            interestBenchmarkRate: [0],
            interestMargin: [0],
            interestBorrowingRate: [0],
            interestRateAdjustment: [""],
            interestAdjustmentDate: [""],
            paymentSummaryType: [0],
            totalPayment: [0],
            noOfInterest: [0],
            paymentDate: [""],
            paymentRepaymentSource: [""],
            paymentFrequency: [""],
            loanSummary: [""],
            collateralRequired: [false],
            collateralType: [0],
            collateralStatus: [""],
            collateralPleged: [false],
            collateralVestedInsitution: [""],
            collateralAppaisalDate: [""],
            collateralAppaisalValue: [0],
            collateralAppaisalVendor: [""],
            collateralShortFall: [0],
            collateralStreetAddress: [""],
            collateralCityState: [""],
            collateralState: [""],
            collateralZipcode: ["", zipCodeValidator],
            collateralCountry: [""],
            collateralLifeInsurance: [false],
            collateralLifeInsuranceValue: [0],
            collateralLifeInsuranceBeneficiary: [""],
            collateralLifeInsuranceIssueing: [""],
            collateralLifeInsuranceAdditional: [""],
            industryAnalysis: [""],
            marketAnalysis: [""],
            franchiseAnalysis: [""],
            financialGrossMargin: [0],
            financialSalesGrowth: [0],
            financialCurrentRatio: [0],
            financialWorkingCapital: [0],
            financialDebtEquity: [0],
            financialDebtCashflow: [0],
            financialDaysCash: [0],
            financialBorrowingCashflow: [""],
            financialBorrowingReAssuarance: [""],
            financialBorrowingProjection: [""],
            financialBorrowingAdditional: [""],
            successionPlanFormalized: [false],
            successionPlanFormalizedText: [""],
            successionPlanOperatinalManagement: [false],
            successionPlanOperatinalManagementText: [""],
            successionPlanPersonPolicy: [false],
            successionPlanPersonPolicyText: [""],
            interestBenchmarkIndexValue: [""],
            rateLockPeriod: [""],
            nextRateAdjustment: [""],
            createdBy: [0],
            lstCreditMemoGuarantorsCreditScore: FormBuilder.array([]),
            lstCreditMemoFee: FormBuilder.array([]),
            lstCreditMemoFin_Exp: FormBuilder.array([]),
            lstCreditMemoBankExposure: FormBuilder.array([]),
            lstCreditMemoStrengthWeakness: FormBuilder.array([]),
            lstCreditMemoThirdPartyReport: FormBuilder.array([]),
            lstCreditMemoKeysMitigantConditionException: FormBuilder.array([]),

            lstCreditMemoStrength: FormBuilder.array([]),
            lstCreditMemoWeakness: FormBuilder.array([]),
            lstCreditMemoKeyRisk: FormBuilder.array([]),
            lstCreditMemoMitigants: FormBuilder.array([]),
            lstCreditMemoCondition: FormBuilder.array([]),
            lstCreditMemoException: FormBuilder.array([]),
            lstCreditMemoFinanicalsAffiliates: FormBuilder.array([]),
            lstCreditMemoFinanicalsGuarantors: FormBuilder.array([]),
            lstCreditMemoGuarantorsExperience: FormBuilder.array([]),
        })
    )

    // Preloader Start
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    useEffect(() => {
        document.title = "Credit Memo - Loan Application";
        setFormData(formData)
        formData.reset()
        getCreditMemoOverviewDetails();
        getCreditMemoMemoDetails();
    }, []);
    // Preloader End

    const getCreditMemoOverviewDetails = async () => {
        try {
            setLoading(true)

            removeAllCheckings(formData, 'lstCreditMemoGuarantorsCreditScore');
            removeAllCheckings(formData, 'lstCreditMemoFee');
            removeAllCheckings(formData, 'lstCreditMemoFin_Exp');
            removeAllCheckings(formData, 'lstCreditMemoBankExposure');
            removeAllCheckings(formData, 'lstCreditMemoThirdPartyReport');
            removeAllCheckings(formData, 'lstCreditMemoStrengthWeakness');

            removeAllCheckings(formData, 'lstCreditMemoStrength');
            removeAllCheckings(formData, 'lstCreditMemoWeakness');
            removeAllCheckings(formData, 'lstCreditMemoKeyRisk');
            removeAllCheckings(formData, 'lstCreditMemoMitigants');
            removeAllCheckings(formData, 'lstCreditMemoCondition');
            removeAllCheckings(formData, 'lstCreditMemoException');
            removeAllCheckings(formData, 'lstCreditMemoFinanicalsAffiliates');
            removeAllCheckings(formData, 'lstCreditMemoFinanicalsGuarantors');
            removeAllCheckings(formData, 'lstCreditMemoGuarantorsExperience');

            const overviewData = await axiosInstance.get('/api/LoanApplication/GetCreditMemo', {
                params: {
                    LoanId: loanid,
                    SessionId: localStorage.getItem('sessionId')
                }
            })
            let data = overviewData.data.resultObject
            if (overviewData.status === 200 && data !== null) {
                setLoading(false)

                data['lstCreditMemoStrength'] = data.lstCreditMemoStrengthWeakness.filter(item => item.type === 'Strength');
                data['lstCreditMemoWeakness'] = data.lstCreditMemoStrengthWeakness.filter(item => item.type === 'Weakness');
                data['lstCreditMemoKeyRisk'] = data.lstCreditMemoKeysMitigantConditionException.filter(item => item.type === 'Key Risk');
                data['lstCreditMemoMitigants'] = data.lstCreditMemoKeysMitigantConditionException.filter(item => item.type === 'Mitigants');
                data['lstCreditMemoCondition'] = data.lstCreditMemoKeysMitigantConditionException.filter(item => item.type === 'Condition');
                data['lstCreditMemoException'] = data.lstCreditMemoKeysMitigantConditionException.filter(item => item.type === 'Exception');
                data['lstCreditMemoFinanicalsAffiliates'] = data.lstCreditMemoFin_Exp.filter(item => item.type === 'Affiliate');
                data['lstCreditMemoFinanicalsGuarantors'] = data.lstCreditMemoFin_Exp.filter(item => item.type === 'Guarantor');
                data['lstCreditMemoGuarantorsExperience'] = data.lstCreditMemoFin_Exp.filter(item => item.type === 'GuarantorEx');

                console.log(data)
                formData.patchValue({
                    ...data,
                    sbaCredit: data.sbaCredit === 1 ? "Yes" : "No",

                    interestType: data.interestType === 'Fixed' ? 'Fixed' : 'Adjustable',
                    paymentFrequency: data.paymentFrequency === 'Weekly' ? 'Weekly' : 'Monthly',
                    collateralStatus: data.collateralStatus ? data.collateralStatus : "",

                    eligibilityCredit: data.eligibilityCredit === true ? "Yes" : "No",
                    collateralRequired: data.collateralRequired === true ? "Yes" : "No",
                    collateralPleged: data.collateralPleged === true ? "Yes" : "No",
                    collateralLifeInsurance: data.collateralLifeInsurance === true ? "Yes" : "No",
                    successionPlanFormalized: data.successionPlanFormalized === true ? "Yes" : "No",
                    successionPlanOperatinalManagement: data.successionPlanOperatinalManagement === true ? "Yes" : "No",
                    successionPlanPersonPolicy: data.successionPlanPersonPolicy === true ? "Yes" : "No",
                    loanId: parseInt(loanid),
                    createdBy: user.id,
                })

                if (data.lstCreditMemoGuarantorsCreditScore.length > 0) returnCreditMemoFormGroupInArray(data.lstCreditMemoGuarantorsCreditScore, formData, "lstCreditMemoGuarantorsCreditScore");
                if (data.lstCreditMemoFee.length > 0) returnCreditMemoFormGroupInArray(data.lstCreditMemoFee, formData, "lstCreditMemoFee");
                if (data.lstCreditMemoFin_Exp.length > 0) returnCreditMemoFormGroupInArray(data.lstCreditMemoFin_Exp, formData, "lstCreditMemoFin_Exp");
                if (data.lstCreditMemoBankExposure.length > 0) returnCreditMemoFormGroupInArray(data.lstCreditMemoBankExposure, formData, "lstCreditMemoBankExposure");
                if (data.lstCreditMemoThirdPartyReport.length > 0) returnCreditMemoFormGroupInArray(data.lstCreditMemoThirdPartyReport, formData, "lstCreditMemoThirdPartyReport");

                data.lstCreditMemoStrength.length > 0 ? returnCreditMemoFormGroupInArray(data.lstCreditMemoStrength, formData, "lstCreditMemoStrength") : addCheckings({ formGroup: formData, keyName: 'lstCreditMemoStrength', type: 'Strength', dispatch })
                data.lstCreditMemoWeakness.length > 0 ? returnCreditMemoFormGroupInArray(data.lstCreditMemoWeakness, formData, "lstCreditMemoWeakness") : addCheckings({ formGroup: formData, keyName: 'lstCreditMemoWeakness', type: 'Weakness', dispatch })
                data.lstCreditMemoKeyRisk.length > 0 ? returnCreditMemoFormGroupInArray(data.lstCreditMemoKeyRisk, formData, "lstCreditMemoKeyRisk") : addCheckings({ formGroup: formData, keyName: 'lstCreditMemoKeyRisk', type: 'Key Risk', dispatch })
                data.lstCreditMemoMitigants.length > 0 ? returnCreditMemoFormGroupInArray(data.lstCreditMemoMitigants, formData, "lstCreditMemoMitigants") : addCheckings({ formGroup: formData, keyName: 'lstCreditMemoMitigants', type: 'Mitigants', dispatch })
                data.lstCreditMemoCondition.length > 0 ? returnCreditMemoFormGroupInArray(data.lstCreditMemoCondition, formData, "lstCreditMemoCondition") : addCheckings({ formGroup: formData, keyName: 'lstCreditMemoCondition', type: 'Condition', dispatch })
                data.lstCreditMemoException.length > 0 ? returnCreditMemoFormGroupInArray(data.lstCreditMemoException, formData, "lstCreditMemoException") : addCheckings({ formGroup: formData, keyName: 'lstCreditMemoException', type: 'Exception', dispatch })

                data.lstCreditMemoGuarantorsExperience.length > 0 && returnCreditMemoFormGroupInArray(data.lstCreditMemoGuarantorsExperience, formData, "lstCreditMemoGuarantorsExperience")
                data.lstCreditMemoFinanicalsAffiliates.length > 0 && returnCreditMemoFormGroupInArray(data.lstCreditMemoFinanicalsAffiliates, formData, "lstCreditMemoFinanicalsAffiliates")
                data.lstCreditMemoFinanicalsGuarantors.length > 0 && returnCreditMemoFormGroupInArray(data.lstCreditMemoFinanicalsGuarantors, formData, "lstCreditMemoFinanicalsGuarantors")
            } else {
                setLoading(false)
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: overviewData.data.message, type: 'error' }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message, type: 'error' }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    const getCreditMemoMemoDetails = async () => {
        try {
            setLoading(true)
            const memoData = await axiosInstance.get('/api/LoanApplication/GetCreditMemoReport', {
                params: {
                    LoanId: loanid,
                    SessionId: localStorage.getItem('sessionId')
                }
            })
            let data = memoData?.data.resultObject
            setLoading(false)
            if (memoData?.status === 200 && data !== null) {

                // Transform the data for EPC and OC
                let tempEpcOc = {
                    EPC: { name: data.epc, ownersList: [] },
                    OC: { name: data.oc, ownersList: [] }
                };

                data.lstCreditMemoEPCOC.forEach((value) => {
                    const key = value.businessName === tempEpcOc.EPC.name ? 'EPC' : 'OC';
                    tempEpcOc[key].ownersList.push(value.owners);
                });

                // Transform the data for Guarantors list
                let tempGurantors = { name: "", ownersList: [] };
                data.lstCreditMemoGuarantor.forEach((value) => {
                    tempGurantors.name = value.businessName;
                    tempGurantors.ownersList.push(value.owners);
                });

                // Transform the data Affiliates list
                let tempAffilates = {};
                data.lstCreditMemoAffiliate.forEach((value) => {
                    if (tempAffilates.hasOwnProperty(value.businessName)) {
                        tempAffilates[value.businessName].ownersList.push(value.owners);
                    } else {
                        tempAffilates[value.businessName] = { ...value, ownersList: value.owners ? [value.owners] : [] }
                    }
                });

                // Transform the data for Strength & Weaknesses
                const tempStrenghtWeakness = data.lstCreditMemoStrengthWeaknessReport.reduce((acc, item) => {
                    let existingRow = acc.find(
                        (row) => row.category === item.category && (row.strength === "-" || row.weakness === "-")
                    );

                    if (!existingRow) {
                        existingRow = { category: item.category, strength: "-", weakness: "-" };
                        acc.push(existingRow);
                    }

                    if (item.type === "Strength" && existingRow.strength === "-") {
                        existingRow.strength = item.details;
                    } else if (item.type === "Weakness" && existingRow.weakness === "-") {
                        existingRow.weakness = item.details;
                    } else {
                        const newRow = { category: item.category, strength: "-", weakness: "-" };
                        if (item.type === "Strength") {
                            newRow.strength = item.details;
                        } else if (item.type === "Weakness") {
                            newRow.weakness = item.details;
                        }
                        acc.push(newRow);
                    }
                    return acc;
                }, []);

                // Transform the data for Key Risk & Mitigants
                const tempMitigantsKeyRisk = [];
                const keyRiskData = data.lstCreditMemoKeysMitigantConditionException.filter(item => item.type === "Key Risk" && item.details !== "");
                let mitigantsData = data.lstCreditMemoKeysMitigantConditionException.filter(item => item.type === "Mitigants" && item.details !== "");

                let maxLength = Math.max(keyRiskData.length, mitigantsData.length);
                for (let i = 0; i < maxLength; i++) {
                    tempMitigantsKeyRisk.push({
                        "Key Risk": keyRiskData[i]?.details || "-",
                        "Mitigants": mitigantsData[i]?.details || "-"
                    });
                }

                // Transform the data for Condition and Exception
                const tempExceptionCondition = [];
                const exceptionData = data.lstCreditMemoKeysMitigantConditionException.filter(item => item.type === "Exception" && item.details !== "");
                const conditionData = data.lstCreditMemoKeysMitigantConditionException.filter(item => item.type === "Condition" && item.details !== "");

                maxLength = Math.max(exceptionData.length, conditionData.length);
                for (let i = 0; i < maxLength; i++) {
                    tempExceptionCondition.push({
                        "Exception": exceptionData[i]?.details || "-",
                        "Condition": conditionData[i]?.details || "-"
                    });
                }

                // Transform the data for Bank Exposure Total row
                let tempCB = 0, tempAC = 0, tempCR = 0, tempTE = 0;
                data.lstCreditMemoBankExposure.forEach((item) => {
                    tempCB += getValue(item.currentBalance);
                    tempAC += getValue(item.availableCredit);
                    tempCR += getValue(item.currentRequest);
                    tempTE += getValue(item.totalExposure);
                })
                setBankExposureTotal({ CB: toFixedOrZero(tempCB), AC: toFixedOrZero(tempAC), CR: toFixedOrZero(tempCR), TE: toFixedOrZero(tempTE) })


                data.lstCreditMemoEPCOC = tempEpcOc;
                data.lstCreditMemoGuarantor = tempGurantors;
                data.lstCreditMemoAffiliate = Object.values(tempAffilates);
                data.lstCreditMemoStrengthWeaknessReport = tempStrenghtWeakness.sort((a, b) => a.category.localeCompare(b.category));
                data.lstCreditMemoKeysMitigantKeyRisk = tempMitigantsKeyRisk;
                data.lstCreditMemoKeysConditionException = tempExceptionCondition;

                console.log("memo data", data)
                setMemoData(data)

            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: memoData?.data.message, type: 'error' }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message, type: 'error' }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    // Credit memo tabs
    const [activeTab, setActiveTab] = useState("Overview");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const [addFeeePopupShow, setAddFeeePopup] = useState(false);
    const addFeeePopupClose = () => {
        setAdditionalFeeFieldValue('')
        setSelectedFeeDataIndex("")
        setAddFeeePopup(false)
    };

    const [UpdateCollateralPopupShow, setUpdateCollateralPopup] = useState(false);
    const UpdateCollateralPopupClose = () => setUpdateCollateralPopup(false);

    const [addReportPopupShow, setAddReportPopup] = useState(false);
    const addReportPopupClose = () => setAddReportPopup(false);


    // -----------------------------------------------------------------------------------------------

    const [selectedFeeDataIndex, setSelectedFeeDataIndex] = useState({});
    const [additionalFeeFieldValue, setAdditionalFeeFieldValue] = useState('');
    const [bankExposureTotal, setBankExposureTotal] = useState({ CB: 0.00, AC: 0.00, CR: 0.00, TE: 0.00 })

    const [thirdPartyReportType, setThirdPartyReportType] = useState('Add');

    const handleClickOnEditFees = (index) => {
        setAdditionalFeeFieldValue(formData.value.lstCreditMemoFee[index].keyName)
        setSelectedFeeDataIndex(index)
        setAddFeeePopup(true)
    }

    const handleSubmitAdditonalFeed = () => {
        if (additionalFeeFieldValue === "") return document.getElementById('additionalFeeFieldValue').focus();

        const checkAlreadyAvailable = formData.value.lstCreditMemoFee.some((fee, index) =>
            fee.isDeleted === 0 &&
            fee.keyName.toLowerCase() === additionalFeeFieldValue.toLowerCase() &&
            (selectedFeeDataIndex === "" || index !== parseInt(selectedFeeDataIndex))
        );

        if (checkAlreadyAvailable) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "This fee is already added", type: 'error' }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
        }

        if (selectedFeeDataIndex !== "") {
            formData.get('lstCreditMemoFee').controls[selectedFeeDataIndex].patchValue({ keyName: additionalFeeFieldValue })
            addFeeePopupClose();
        } else {
            addCheckings({ formGroup: formData, keyName: 'lstCreditMemoFee', fieldName: additionalFeeFieldValue, dispatch });
            addFeeePopupClose();
        }
    }

    const handleRemoveAdditionalFeed = () => {
        removeCheckings(selectedFeeDataIndex, formData, 'lstCreditMemoFee');
        addFeeePopupClose();
    }

    const handleRemoveThirdPartyReport = () => {
        removeCheckings(thirdPartyReportFormGroup.value.index, formData, 'lstCreditMemoThirdPartyReport');
        addReportPopupClose();
    }

    const handleSubmitThirdPartyReport = () => {
        if (thirdPartyReportFormGroup.status === "VALID") {
            if (thirdPartyReportType === 'Add') {
                thirdPartyReportFormGroup.patchValue({ creditMemoId: formData.get('id').value })
                let groupControl = formData.get("lstCreditMemoThirdPartyReport");
                const finalData = {
                    ...thirdPartyReportFormGroup.value,
                    creditMemoId: formData.get('id').value,
                    reportDate: thirdPartyReportFormGroup.value.reportDate ? moment(thirdPartyReportFormGroup.value.reportDate).format("MM/DD/YYYY") : ""
                };
                const newReport = createLstCreditMemoThirdPartyReportList(finalData);
                groupControl.push(newReport);
                formData.markAsDirty();
            } else {
                formData.get('lstCreditMemoThirdPartyReport').controls[thirdPartyReportFormGroup.value.index].patchValue(thirdPartyReportFormGroup.value)
            }
            thirdPartyReportFormGroup.reset();
            addReportPopupClose();
        } else {
            markAllAsTouched(thirdPartyReportFormGroup)
        }
    }
    // -----------------------------------------------------------------------------------------------


    // Marking all input fields as touched if the form is not valid
    const markAllAsTouched = (formGroup) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                console.log(controlName)
                control.active = true;
                control.touched = true;
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    };

    const RiskGradeDropdownOptions = [
        { value: "", label: "Select..." },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
    ]

    const FrequencyDropdownOptions = [
        { value: "", label: "Select..." },
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Annually", label: "Annually" }
    ]

    const handleSubmitCreditMemoOverview = async () => {
        try {
            if (formData.status === "VALID") {

                const tempData = formData.value;
                let finalData = {
                    ...formData.value,
                    interestBorrowingRate: (parseFloat(tempData.interestBenchmarkRate) + parseFloat(tempData.interestMargin)).toFixed(2),

                    sbaFee: tempData.loanType !== 1 ? tempData.sbaFee : "",
                    sbaSmallSize: tempData.loanType !== 1 ? tempData.sbaSmallSize : "",
                    sbaCredit: tempData.loanType !== 1 ? tempData.sbaCredit === "Yes" ? 1 : 0 : 1,

                    eligibilitySmallSize: tempData.loanType !== 1 ? tempData.eligibilitySmallSize : "",
                    eligibilityCredit: tempData.loanType !== 1 ? tempData.eligibilityCredit === "Yes" : true,

                    collateralRequired: tempData.collateralRequired === "Yes",
                    collateralPleged: tempData.collateralPleged === "Yes",
                    collateralLifeInsurance: tempData.collateralLifeInsurance === "Yes",
                    successionPlanFormalized: tempData.successionPlanFormalized === "Yes",
                    successionPlanOperatinalManagement: tempData.successionPlanOperatinalManagement === "Yes",
                    successionPlanPersonPolicy: tempData.successionPlanPersonPolicy === "Yes",

                    collateralLifeInsuranceValue: tempData.collateralLifeInsurance === "Yes" ? tempData.collateralLifeInsuranceValue : "0.00",
                    collateralLifeInsuranceBeneficiary: tempData.collateralLifeInsurance === "Yes" ? tempData.collateralLifeInsuranceBeneficiary : "",
                    collateralLifeInsuranceIssueing: tempData.collateralLifeInsurance === "Yes" ? tempData.collateralLifeInsuranceIssueing : "",

                    collateralType: tempData.collateralRequired === "Yes" ? tempData.collateralType : 0,
                    collateralStatus: tempData.collateralRequired === "Yes" ? tempData.collateralStatus : "",
                    collateralVestedInsitution: tempData.collateralRequired === "Yes" ? tempData.collateralVestedInsitution : "",
                    collateralAppaisalDate: tempData.collateralRequired === "Yes" ? checkAndFormatDate(tempData.collateralAppaisalDate) : "",
                    collateralAppaisalValue: tempData.collateralRequired === "Yes" ? tempData.collateralAppaisalValue : "0.00",
                    collateralAppaisalVendor: tempData.collateralRequired === "Yes" ? tempData.collateralAppaisalVendor : "",
                    collateralShortFall: tempData.collateralRequired === "Yes" ? tempData.collateralShortFall : "0.00",
                    collateralStreetAddress: tempData.collateralRequired === "Yes" ? tempData.collateralStreetAddress : "",
                    collateralCityState: tempData.collateralRequired === "Yes" ? tempData.collateralCityState : "",
                    collateralState: tempData.collateralRequired === "Yes" ? tempData.collateralState : "",
                    collateralZipcode: tempData.collateralRequired === "Yes" ? tempData.collateralZipcode : "",
                    collateralCountry: tempData.collateralRequired === "Yes" ? tempData.collateralCountry : "",

                    interestBenchmarkIndexValue: tempData.interestType === 'Fixed' ? tempData.interestBenchmarkIndexValue : "",
                    rateLockPeriod: tempData.interestType === 'Fixed' ? tempData.rateLockPeriod : "",
                    nextRateAdjustment: tempData.interestType === 'Fixed' ? tempData.nextRateAdjustment : "",

                    interestAdjustmentDate: tempData.interestType === 'Adjustable' ? tempData.interestAdjustmentDate : "",
                    interestRateAdjustment: tempData.interestType === 'Adjustable' ? tempData.interestRateAdjustment : "",

                    successionPlanFormalizedText: tempData.successionPlanFormalized === "Yes" ? tempData.successionPlanFormalizedText : "",
                    successionPlanOperatinalManagementText: tempData.successionPlanOperatinalManagement === "Yes" ? tempData.successionPlanOperatinalManagementText : "",
                    successionPlanPersonPolicyText: tempData.successionPlanPersonPolicy === "Yes" ? tempData.successionPlanPersonPolicyText : "",

                    lstCreditMemoFin_Exp: tempData.lstCreditMemoFinanicalsAffiliates.concat(formData.value.lstCreditMemoFinanicalsGuarantors, tempData.lstCreditMemoGuarantorsExperience),
                    lstCreditMemoStrengthWeakness: [...tempData.lstCreditMemoStrength, ...formData.value.lstCreditMemoWeakness].filter(item => item.categoryId !== ''),
                    lstCreditMemoKeysMitigantConditionException: tempData.lstCreditMemoKeyRisk.concat(formData.value.lstCreditMemoMitigants, tempData.lstCreditMemoCondition, tempData.lstCreditMemoException)
                }



                delete finalData.lstCreditMemoFinanicalsAffiliates;
                delete finalData.lstCreditMemoFinanicalsGuarantors;
                delete finalData.lstCreditMemoGuarantorsExperience;
                delete finalData.lstCreditMemoStrength;
                delete finalData.lstCreditMemoWeakness;
                delete finalData.lstCreditMemoKeyRisk;
                delete finalData.lstCreditMemoMitigants;
                delete finalData.lstCreditMemoCondition;
                delete finalData.lstCreditMemoException;

                console.log("data submitted", finalData)

                setLoading(true)
                const response = await axiosInstance.post('/api/LoanApplication/InsertUpdateCreditMemo', finalData, {
                    params: {
                        SessionId: localStorage.getItem('sessionId')
                    }
                })
                if (response.status === 200) {
                    setLoading(false)
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: 'success' }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    formData.reset()
                    getCreditMemoOverviewDetails()
                    getCreditMemoMemoDetails()
                }
            } else {
                markAllAsTouched(formData)
            }
        } catch (error) {
            setLoading(false)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message, type: 'error' }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    const handleConfirmGenerateAI = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get('/api/OpenAI/GetAIGuarantorExperience_Industry_Market_FranchiseAnalysis', {
                params: {
                    LoanId: loanid,
                    SessionId: localStorage.getItem('sessionId')
                }
            })
            if (response.status === 200) {
                setLoading(false)
                setUpdateCollateralPopup(false)
                const formattedData = response.data.resultObject.reduce((acc, element) => {
                    acc[element.key] = element;
                    return acc;
                }, {});

                const updatedList = formData.value.lstCreditMemoGuarantorsExperience.map((item) => {
                    if (formattedData.hasOwnProperty(item.userName)) {
                        item.remarks = formattedData[item.userName].value;
                    }
                    return item
                })
                returnCreditMemoFormGroupInArray(updatedList, formData, "lstCreditMemoGuarantorsExperience")
                formData.patchValue({ industryAnalysis: formattedData.Industry.value, marketAnalysis: formattedData.Marketing.value, franchiseAnalysis: formattedData.Franchise.value })
            }
        } catch (error) {
            setLoading(false)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message, type: 'error' }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    return (
        <>
            <SidePanel />
            <ProfileHeader />
            {loading ? <Preloader /> : (
                <div>
                    <div className="lm-nav-tabs credit-memo-tabs radio-tabs">
                        <ul className="nav nav-tabs" id="laTab" role="tablist">
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === "Overview" ? "active" : ""}`} onClick={() => handleTabClick("Overview")}>
                                    Overview
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeTab === "Memo" ? "active" : ""}`} onClick={() => handleTabClick("Memo")}>
                                    Memo
                                </button>
                            </li>
                        </ul>
                    </div>


                    <div className="tab-content pt-3" id="laTabContent">
                        <div className={`tab-pane fade ${activeTab === "Overview" ? "show active" : ""}`} id="Overview">
                            <div className="lm-form-box credit-memo-wrap pb-5">
                                <FieldGroup
                                    control={formData}
                                    render={({ get }) => (
                                        <>
                                            <div className="lm-card">
                                                <h3 className="lm-card-heading">Loan Type</h3>
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <label htmlFor="loan_type">Loan Type</label>
                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                }}
                                                                options={staticCreditMemoLoanType}
                                                                value={staticCreditMemoLoanType.find(type => type.value === formData.value.loanType)}
                                                                onChange={(e) => formData.patchValue({ loanType: e.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData.value.loanType !== 1 && (
                                                    <>
                                                        <div className="lm-card">
                                                            <h3 className="lm-card-heading">SBA</h3>
                                                            <div className="row">
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-dollar">
                                                                        <AmountContainer
                                                                            name="sbaFee"
                                                                            label="SBA guaranty fees"
                                                                            placeholder="0.00"
                                                                            id="sbaFee"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <AmountContainer
                                                                            name="sbaSmallSize"
                                                                            label="Small size"
                                                                            placeholder="0.00"
                                                                            id="sbaSmallSize"
                                                                            hideIcon={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormRadio
                                                                            name="sbaCredit"
                                                                            label="Credit available elsewhere"
                                                                            options={[
                                                                                { value: "Yes", label: "Yes" },
                                                                                { value: "No", label: "No" }
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="lm-card">
                                                            <h3 className="lm-card-heading">Eligibility</h3>
                                                            <div className="row">
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <AmountContainer
                                                                            name="eligibilitySmallSize"
                                                                            label="Small size"
                                                                            placeholder="0.00"
                                                                            id="eligibilitySmallSize"
                                                                            hideIcon={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormRadio
                                                                            name="eligibilityCredit"
                                                                            label="Credit available elsewhere"
                                                                            options={[
                                                                                { value: "Yes", label: "Yes" },
                                                                                { value: "No", label: "No" }
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Start Checkboxes */}
                                                            {/* <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="businessIndustryApplicant"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="businessIndustryApplicant" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ businessIndustryApplicant: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="businessIndustryApplicant">Business industry of the applicant</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="applicationOperationTwoOrLess"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="applicationOperationTwoOrLess" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ applicationOperationTwoOrLess: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="applicationOperationTwoOrLess">The applicant has been in operation of two years or less</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="inadequacyCOllateralAvailable"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="inadequacyCOllateralAvailable" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ inadequacyCOllateralAvailable: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="inadequacyCOllateralAvailable">Inadequacy collateral available to secure the loan</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="requestedLoanExceedsPolicy"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="requestedLoanExceedsPolicy" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ requestedLoanExceedsPolicy: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="requestedLoanExceedsPolicy">Requested loan exceeds our policy limit regarding the amount that we can lend to one customer</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="loanTermNecessaryReasonable"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="loanTermNecessaryReasonable" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ loanTermNecessaryReasonable: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="loanTermNecessaryReasonable">Loan term necessary to reasonably assure repayment of loan from actual or projected cash flow</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="businessNeedsLongerMaturity"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="businessNeedsLongerMaturity" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ businessNeedsLongerMaturity: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="businessNeedsLongerMaturity">Business needs longer maturity than our conventional policy permits</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="overallAdvanceRateExceeds"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="overallAdvanceRateExceeds" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ overallAdvanceRateExceeds: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="overallAdvanceRateExceeds">The overall advance rate exceeds our conventional maximum for this type of property.</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="miscellaneousSelects"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="miscellaneousSelects" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ miscellaneousSelects: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="miscellaneousSelects">Miscellaneous: (select one or more):</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="managementExperience"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="managementExperience" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ managementExperience: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="managementExperience">Management experience</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="creditHistory"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="creditHistory" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ creditHistory: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="creditHistory">Credit History</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="leverageRatio"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="leverageRatio" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ leverageRatio: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="leverageRatio">Leverage ratio</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="globalCashflow"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="globalCashflow" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ globalCashflow: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="globalCashflow">Global cashflow</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="loanSizeRelativeToAge"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="loanSizeRelativeToAge" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ loanSizeRelativeToAge: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="loanSizeRelativeToAge">Loan size relative to the age of the business</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="personalResourcesOfOwners"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="personalResourcesOfOwners" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ personalResourcesOfOwners: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="personalResourcesOfOwners">Personal resources of the owners of the business.</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="form-group mb-2 form-check d-flex align-items-start">
                                                                        <FieldControl
                                                                            name="otherEligibilityCheckbox"
                                                                            render={({ handler }) => (
                                                                                <>
                                                                                    <div>
                                                                                        <input type="checkbox" className="form-check-input" id="otherEligibilityCheckbox" checked={handler().value}
                                                                                            onChange={(e) => {
                                                                                                handler().onChange(e.target.checked)
                                                                                                formData.patchValue({ otherEligibilityCheckbox: e.target.checked })
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label className="form-check-label" htmlFor="otherEligibilityCheckbox">Other</label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-10'>
                                                                    <FormField
                                                                        name="otherEligibility"
                                                                        id="otherEligibility"
                                                                        placeholder="Enter other eligibility"
                                                                        hideLabel={true}
                                                                    />
                                                                </div>

                                                            </div> */}
                                                            {/* End Checkboxes */}
                                                        </div>
                                                    </>
                                                )
                                            }

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading">Interest Rate</h3>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <FormRadio
                                                                name="interestType"
                                                                label="Interest rate type"
                                                                options={[
                                                                    { value: "Adjustable", label: "Adjustable" },
                                                                    { value: "Fixed", label: "Fixed" }
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-date">
                                                            <label htmlFor="Benchmark_index">Benchmark index</label>
                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                }}
                                                                options={staticCreditMemoBenchmarkindex}
                                                                value={staticCreditMemoBenchmarkindex.find(type => type.value === formData.value.interestBenchmarkIndex)}
                                                                onChange={(e) => formData.patchValue({ interestBenchmarkIndex: e.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        formData.value.interestType === 'Fixed' && (
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group">
                                                                    <FormField
                                                                        name="interestBenchmarkIndexValue"
                                                                        id="interestBenchmarkIndexValue"
                                                                        label="Benchmark index value"
                                                                        placeholder="Enter Benchmark index value"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-percentage">
                                                            <AmountContainer
                                                                name="interestBenchmarkRate"
                                                                id="interestBenchmarkRate"
                                                                label="Benchmark rate (%)"
                                                                placeholder="0.00"
                                                                setMinValidation={true}
                                                                setMaxValidation={true}
                                                                minValue={0}
                                                                maxValue={100}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-percentage">
                                                            <AmountContainer
                                                                name="interestMargin"
                                                                id="interestMargin"
                                                                label="Margin (%)"
                                                                placeholder="0.00"
                                                                setMinValidation={true}
                                                                setMaxValidation={true}
                                                                minValue={0}
                                                                maxValue={100}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-percentage">
                                                            <label htmlFor="interestBorrowingRate">Borrowing rate (%)</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="interestBorrowingRate"
                                                                placeholder="0.00"
                                                                value={(getValue(formData.value.interestBenchmarkRate) + getValue(formData.value.interestMargin)).toFixed(2)}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        formData.value.interestType === 'Adjustable' && (
                                                            <>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <label htmlFor="adjustmentFrequency">Adjustment Frequency</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={FrequencyDropdownOptions}
                                                                        value={FrequencyDropdownOptions.find(type => type.value === formData.value.interestRateAdjustment)}
                                                                        onChange={(e) => {
                                                                            formData.patchValue({ interestRateAdjustment: e.value })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-date">
                                                                        <label htmlFor="interestAdjustmentDate">First rate adjustment date</label>
                                                                        <DatePicker
                                                                            showIcon
                                                                            toggleCalendarOnIconClick
                                                                            selected={formData.value.interestAdjustmentDate ? moment(formData.value.interestAdjustmentDate).toDate() : ''}
                                                                            value={formData.value.interestAdjustmentDate ? moment(formData.value.interestAdjustmentDate).format('ll') : ""}
                                                                            isClearable={formData.value.interestAdjustmentDate}
                                                                            dateFormat="MMM d, yyyy"
                                                                            placeholderText="Month DD, YYYY"
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableYearDropdown
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            onChange={(date) => {
                                                                                const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                                formData.patchValue({ interestAdjustmentDate: formattedDate })
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        formData.value.interestType === 'Fixed' && (
                                                            <>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="rateLockPeriod"
                                                                            id="rateLockPeriod"
                                                                            label="Rate lock period"
                                                                            placeholder="Enter Rate lock period"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-date">
                                                                        <label htmlFor="nextRateAdjustment">Next rate adjustment</label>
                                                                        <DatePicker
                                                                            showIcon
                                                                            toggleCalendarOnIconClick
                                                                            selected={formData.value.nextRateAdjustment ? moment(formData.value.nextRateAdjustment).toDate() : ''}
                                                                            value={formData.value.nextRateAdjustment ? moment(formData.value.nextRateAdjustment).format('ll') : ""}
                                                                            isClearable={formData.value.nextRateAdjustment}
                                                                            dateFormat="MMM d, yyyy"
                                                                            placeholderText="Month DD, YYYY"
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableYearDropdown
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            onChange={(date) => {
                                                                                const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                                formData.patchValue({ nextRateAdjustment: formattedDate })
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                </div>
                                            </div>

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading">Payment Summary</h3>
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-date">
                                                            <label htmlFor="Payment_type">Payment type</label>
                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                }}
                                                                options={staticCreditMemoPaymentType}
                                                                value={staticCreditMemoPaymentType.find(type => type.value === formData.value.paymentSummaryType)}
                                                                onChange={(e) => formData.patchValue({ paymentSummaryType: e.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-month-text">
                                                            <FormField
                                                                name="totalPayment"
                                                                id="totalPayment"
                                                                label="Total number of payments"
                                                                placeholder="Enter month"
                                                                onlyNumbers={true}
                                                                setMinValidation={true}
                                                                setMaxValidation={true}
                                                                minValue={0}
                                                                maxValue={999}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-month-text">
                                                            <FormField
                                                                name="noOfInterest"
                                                                id="noOfInterest"
                                                                label="Number of Interest only payments"
                                                                placeholder="Enter month"
                                                                onlyNumbers={true}
                                                                setMinValidation={true}
                                                                setMaxValidation={true}
                                                                minValue={0}
                                                                maxValue={999}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-date">
                                                            <label htmlFor="Anticipated_first_payment_date">Anticipated first payment date</label>
                                                            <DatePicker
                                                                showIcon
                                                                toggleCalendarOnIconClick
                                                                selected={formData.value.paymentDate ? moment(formData.value.paymentDate).toDate() : ''}
                                                                value={formData.value.paymentDate ? moment(formData.value.paymentDate).format('ll') : ""}
                                                                isClearable={formData.value.paymentDate}
                                                                dateFormat="MMM d, yyyy"
                                                                placeholderText="Month DD, YYYY"
                                                                yearDropdownItemNumber={100}
                                                                scrollableYearDropdown
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                onChange={(date) => {
                                                                    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                    formData.patchValue({ paymentDate: formattedDate })
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <FormField
                                                                name="paymentRepaymentSource"
                                                                label="Repayment source"
                                                                placeholder="Write Repayment Source"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <FormRadio
                                                                name="paymentFrequency"
                                                                label="Payment frequency"
                                                                options={[
                                                                    { value: "Weekly", label: "Weekly" },
                                                                    { value: "Monthly", label: "Monthly" }
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading">Loan Summary</h3>
                                                <div className="row">
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className="form-group AI_btn_field">
                                                                    <FormTextarea
                                                                        name="loanSummary"
                                                                        label="Loan request"
                                                                        placeholder="Provide detail (optional)"
                                                                        id="loanSummary"
                                                                    />
                                                                    {/* AI Update Buttn */}
                                                                    <OpenAIComponent
                                                                        fieldName="loanSummary"
                                                                        formGroup={formData}
                                                                        type="textarea"
                                                                        AiType="AiUpdate"
                                                                        loanId={loanid}
                                                                        loanType={staticCreditMemoLoanType.find(type => type.value === formData.value.loanType)?.label}
                                                                        ownerId={0}
                                                                        businessOwnerName=""
                                                                        action="LoanSummary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3 className="lm-card-heading">Guarantors</h3>
                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoGuarantorsCreditScore")}
                                                    render={(guarantorsData, index) => (
                                                        <div className='row'>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className='Guarantors_NAME w-100 mt-4 py-2 lender-user-name'>
                                                                    <img src={user_avtar_grey} alt="" />
                                                                    {guarantorsData.value.userName}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group">
                                                                    <FormField
                                                                        name={`lstCreditMemoGuarantorsCreditScore.${index}.guarantorsCreditScore`}
                                                                        id={`lstCreditMemoGuarantorsCreditScore.${index}.guarantorsCreditScore`}
                                                                        label="Credit score"
                                                                        placeholder="Enter credit score"
                                                                        onlyNumbers={true}
                                                                        setMinValidation={true}
                                                                        setMaxValidation={true}
                                                                        minValue={0}
                                                                        maxValue={999}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Score_date">Score date</label>
                                                                    <FieldControl
                                                                        name={`lstCreditMemoGuarantorsCreditScore.${index}.guarantorsCreditScoreDate`}
                                                                        render={({ handler, touched, hasError, meta }) => (
                                                                            <DatePicker
                                                                                showIcon
                                                                                toggleCalendarOnIconClick
                                                                                {...handler()}
                                                                                selected={handler().value ? moment(handler().value).toDate() : ''}
                                                                                value={checkAndFormatDate(formData.value.lstCreditMemoGuarantorsCreditScore[index].guarantorsCreditScoreDate)}
                                                                                isClearable={handler().value}
                                                                                dateFormat="MMM d, yyyy"
                                                                                placeholderText="Month DD, YYYY"
                                                                                yearDropdownItemNumber={100}
                                                                                scrollableYearDropdown
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                onChange={(date) => {
                                                                                    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                                    handler().onChange(formattedDate);
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Credit_agency">Credit agency</label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={staticCreditMemoAgent}
                                                                        value={staticCreditMemoAgent.find(type => type.value === guarantorsData.value.guarantorsCreditAgency)}
                                                                        onChange={(e) => {
                                                                            formData.patchValue({
                                                                                lstCreditMemoGuarantorsCreditScore: formData.value.lstCreditMemoGuarantorsCreditScore.map((item, i) => {
                                                                                    if (i === index) {
                                                                                        item.guarantorsCreditAgency = e.value
                                                                                    }
                                                                                    return item
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>

                                            <div className="lm-card">
                                                <div className="d-flex justify-content-between">
                                                    <h3 className="lm-card-heading">Fee(s)</h3>
                                                    <a onClick={() => {
                                                        setAdditionalFeeFieldValue('')
                                                        setSelectedFeeDataIndex("")
                                                        setAddFeeePopup(true)
                                                    }} className="lm-button3"><span>+</span> Add fee</a>
                                                </div>
                                                <div className="row">
                                                    <FormArrayComponent
                                                        control={formData.get("lstCreditMemoFee")}
                                                        render={(feesData, index) => (
                                                            feesData.value.isDeleted === 0 && (
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-dollar dynamic-form-group">
                                                                        <label htmlFor="Fee_Origination">{formData.value.lstCreditMemoFee[index].keyName}
                                                                            <button className='ml-2 no-css-btn' onClick={() => handleClickOnEditFees(index)}                                                                        >
                                                                                <img width={14} src={edit_pen} alt="" />
                                                                            </button>
                                                                        </label>
                                                                        <AmountContainer
                                                                            name={`lstCreditMemoFee.${index}.keyValue`}
                                                                            id={`lstCreditMemoFee.${index}.keyValue`}
                                                                            placeholder="0.00"
                                                                            hideLabel={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    />
                                                </div>
                                            </div>


                                            <div className="lm-card">
                                                <h3 className="lm-card-heading">Collateral</h3>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <FormRadio
                                                                name="collateralRequired"
                                                                label="Collateral required"
                                                                options={[
                                                                    { value: "Yes", label: "Yes" },
                                                                    { value: "No", label: "No" }
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        formData.value.collateralRequired === 'Yes' && (
                                                            <>

                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-date">
                                                                        <label htmlFor="Collateral_type">Type</label>
                                                                        <Select
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            options={staticCreditMemoCollateralType}
                                                                            value={staticCreditMemoCollateralType.find(type => type.value === formData.value.collateralType)}
                                                                            onChange={(e) => {
                                                                                formData.patchValue({
                                                                                    collateralType: e.value,
                                                                                    collateralStatus: e.value === 1 || e.value === 2 ? "Owner Occupied" : e.value === 3 ? "New" : ""
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {
                                                                    formData.value.collateralType !== 0 && (
                                                                        <div className="col-md-4 col-lg-3">
                                                                            <div className="form-group mb-2">
                                                                                {
                                                                                    formData.value.collateralType === 1 || formData.value.collateralType === 2 ? (
                                                                                        <FormRadio
                                                                                            key="ownerRental"
                                                                                            name="collateralStatus"
                                                                                            label="Status"
                                                                                            options={[
                                                                                                { value: "Owner Occupied", label: "Owner Occupied" },
                                                                                                { value: "Rental", label: "Rental" }
                                                                                            ]}
                                                                                        />
                                                                                    ) : formData.value.collateralType === 3 ? (
                                                                                        <FormRadio
                                                                                            key="newUsed"
                                                                                            name="collateralStatus"
                                                                                            label="Status"
                                                                                            options={[
                                                                                                { value: "New", label: "New" },
                                                                                                { value: "Used", label: "Used" }
                                                                                            ]}
                                                                                        />
                                                                                    ) : (
                                                                                        <FormField
                                                                                            name="collateralStatus"
                                                                                            label="Detail"
                                                                                            placeholder="Enter detail"
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group mb-2">
                                                                        <FormRadio
                                                                            name="collateralPleged"
                                                                            label="Pledged"
                                                                            tooltipEnabled={true}
                                                                            tooltipContent='Select "Yes" if there is lien on the collateral by another lender'
                                                                            options={[
                                                                                { value: "Yes", label: "Yes" },
                                                                                { value: "No", label: "No" }
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="collateralVestedInsitution"
                                                                            label="Vested institution"
                                                                            placeholder="Enter text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-date">
                                                                        <label htmlFor="Appraisal_date">Appraisal date</label>
                                                                        <DatePicker
                                                                            showIcon
                                                                            toggleCalendarOnIconClick
                                                                            selected={formData.value.collateralAppaisalDate ? moment(formData.value.collateralAppaisalDate).toDate() : ""}
                                                                            value={formData.value.collateralAppaisalDate ? moment(formData.value.collateralAppaisalDate).format("ll") : ""}
                                                                            isClearable={formData.value.collateralAppaisalDate}
                                                                            dateFormat="MMM d, yyyy"
                                                                            placeholderText="Month DD, YYYY"
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableYearDropdown
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            onChange={(date) => {
                                                                                const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                                formData.patchValue({ collateralAppaisalDate: formattedDate });
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-dollar">
                                                                        <AmountContainer
                                                                            name="collateralAppaisalValue"
                                                                            id="collateralAppaisalValue"
                                                                            label="Appraisal value"
                                                                            placeholder="0.00"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="collateralAppaisalVendor"
                                                                            label="Appraisal vendor"
                                                                            placeholder="Enter text"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-dollar">
                                                                        <AmountContainer
                                                                            name="collateralShortFall"
                                                                            id="collateralShortFall"
                                                                            label="Shortfall"
                                                                            placeholder="0.00"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-12'>
                                                                    <b className="d-flex py-2">Location
                                                                        <div className="lm-tooltip ml-2" data-tooltip="Please enter address for collateral"
                                                                            data-tooltip-pos="up" data-tooltip-length="fit"> <img src={Info_tooltip} alt="" /> </div>
                                                                    </b>
                                                                </div>
                                                                <div className="col-md-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="collateralStreetAddress"
                                                                            label="Street address"
                                                                            placeholder="Enter street address"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="collateralCityState"
                                                                            label="City"
                                                                            placeholder="Enter city"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-date">
                                                                        <label htmlFor="Country">State</label>
                                                                        <Select
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            options={staticStatesListOptions}
                                                                            value={staticStatesListOptions.find(type => type.value === formData.value.collateralState)}
                                                                            onChange={(e) => formData.patchValue({ collateralState: e.value })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="collateralZipcode"
                                                                            id="collateralZipcode"
                                                                            label="ZIP Code"
                                                                            placeholder="Enter ZIP Code"
                                                                            patternRequired={true}
                                                                            patternMessage="Please enter valid ZIP Code"
                                                                            maxlength={5}
                                                                            errorMessage="Zip Code is required"
                                                                            onlyNumbers={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-date">
                                                                        <label htmlFor="Country">Country</label>
                                                                        <Select
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            options={staticCountryList}
                                                                            value={staticCountryList.find(type => type.value === formData.value.collateralCountry)}
                                                                            onChange={(e) => formData.patchValue({ collateralCountry: e.value })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                    {/* <!-- lm common divider to break the section  --> */}
                                                    <div className="lm-divider my-3 mx-3"></div>
                                                    {/* <!-- lm common divider to break the section  --> */}

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-2">
                                                            <FormRadio
                                                                name="collateralLifeInsurance"
                                                                label="Life insurance"
                                                                options={[
                                                                    { value: "Yes", label: "Yes" },
                                                                    { value: "No", label: "No" }
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        formData.value.collateralLifeInsurance === 'Yes' && (
                                                            <>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group input-dollar">
                                                                        <AmountContainer
                                                                            name="collateralLifeInsuranceValue"
                                                                            id="collateralLifeInsuranceValue"
                                                                            label="Value"
                                                                            placeholder="0.00"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="collateralLifeInsuranceBeneficiary"
                                                                            label="Beneficiary"
                                                                            placeholder="Enter beneficiary"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-lg-3">
                                                                    <div className="form-group">
                                                                        <FormField
                                                                            name="collateralLifeInsuranceIssueing"
                                                                            label="Issuing company"
                                                                            placeholder="Enter issuing company"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }

                                                    <div className="col-md-6">
                                                        <div className="form-group AI_btn_field">
                                                            <FormTextarea
                                                                name="collateralLifeInsuranceAdditional"
                                                                placeholder="Provide detail (optional)"
                                                                id="collateralLifeInsuranceAdditional"
                                                                label="Additional information"
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="collateralLifeInsuranceAdditional"
                                                                formGroup={formData}
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='d-flex justify-content-end mb-3'>
                                                <button onClick={() => setUpdateCollateralPopup(true)} type="button" className="lm-button1">Generate</button>
                                            </div>

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading">Guarantor Experience</h3>

                                                <div className='row'>
                                                    <FormArrayComponent
                                                        control={formData.get("lstCreditMemoGuarantorsExperience")}
                                                        render={(guarantorExp, index) => (
                                                            <div className='col-md-6'>
                                                                <div className="form-group AI_btn_field">
                                                                    <label className='d-flex text-dark mb-2 f-600' htmlFor="Guarantor_Experience">
                                                                        <img width={14} className='mr-2' src={user_avtar_grey} alt="" />
                                                                        {guarantorExp.value.userName}
                                                                    </label>
                                                                    <FormTextarea
                                                                        name={`lstCreditMemoGuarantorsExperience.${index}.remarks`}
                                                                        placeholder="Provide detail (optional)"
                                                                        id={`lstCreditMemoGuarantorsExperience.${index}.remarks`}
                                                                        hideLabel={true}
                                                                    />
                                                                    <OpenAIComponent
                                                                        fieldName={`lstCreditMemoGuarantorsExperience.${index}.remarks`}
                                                                        formGroup={formData}
                                                                        type="textarea"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="lm-card">
                                                        <h3 className="lm-card-heading">Industry Analysis</h3>
                                                        <div className="form-group AI_btn_field mb-0">
                                                            <FormTextarea
                                                                name="industryAnalysis"
                                                                placeholder="Provide detail (optional)"
                                                                id="industryAnalysis"
                                                                hideLabel={true}
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="industryAnalysis"
                                                                formGroup={formData}
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="lm-card">
                                                        <h3 className="lm-card-heading">Market Analysis</h3>
                                                        <div className="form-group AI_btn_field mb-0">
                                                            <FormTextarea
                                                                name="marketAnalysis"
                                                                placeholder="Provide detail (optional)"
                                                                id="marketAnalysis"
                                                                hideLabel={true}
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="marketAnalysis"
                                                                formGroup={formData}
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="lm-card">
                                                        <h3 className="lm-card-heading">Franchise Analysis</h3>
                                                        <div className="form-group AI_btn_field mb-0">
                                                            <FormTextarea
                                                                name="franchiseAnalysis"
                                                                placeholder="Provide detail (optional)"
                                                                id="franchiseAnalysis"
                                                                hideLabel={true}
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="franchiseAnalysis"
                                                                formGroup={formData}
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading mb-3">Financial Analysis</h3>

                                                <h5 className='text-uppercase f-600 mb-2'>Financial Ratios</h5>
                                                <div className='row'>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-percentage">
                                                            <AmountContainer
                                                                name="financialGrossMargin"
                                                                id="financialGrossMargin"
                                                                label="Gross margin"
                                                                placeholder="0.00"
                                                                tooltipEnabled={true}
                                                                tooltipContent="((Revenue – COGS) / Revenue) x 100."
                                                                disabled={true}
                                                                hideIcon={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <AmountContainer
                                                                name="financialSalesGrowth"
                                                                id="financialSalesGrowth"
                                                                label="Sales growth"
                                                                placeholder="0.00"
                                                                tooltipEnabled={true}
                                                                tooltipContent="(Current Year Sales – Prior Year Sales) / Prior Year Sales) x 100"
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <AmountContainer
                                                                name="financialCurrentRatio"
                                                                id="financialCurrentRatio"
                                                                label="Current ratio"
                                                                placeholder="0.00"
                                                                tooltipEnabled={true}
                                                                tooltipContent="Current Assets / Current Liabilities"
                                                                disabled={true}
                                                                hideIcon={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                name="financialWorkingCapital"
                                                                id="financialWorkingCapital"
                                                                label="Working capital"
                                                                placeholder="0.00"
                                                                tooltipEnabled={true}
                                                                tooltipContent="Current Assets – Current Liabilities"
                                                                disabled={true}
                                                                hideIcon={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <AmountContainer
                                                                name="financialDebtEquity"
                                                                id="financialDebtEquity"
                                                                label="Debt to equity"
                                                                placeholder="0.00"
                                                                tooltipEnabled={true}
                                                                tooltipContent="Total Liabilities / Shareholder’s Equity"
                                                                disabled={true}
                                                                hideIcon={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <AmountContainer
                                                                name="financialDebtCashflow"
                                                                id="financialDebtCashflow"
                                                                label="Debt to cash flow"
                                                                placeholder="0.00"
                                                                tooltipEnabled={true}
                                                                tooltipContent="Cash Flow from Operations/ Total Business Debt"
                                                                disabled={true}
                                                                hideIcon={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-lg-3">
                                                        <div className="form-group">
                                                            <AmountContainer
                                                                name="financialDaysCash"
                                                                id="financialDaysCash"
                                                                label="Days cash on hand"
                                                                placeholder="0.00"
                                                                tooltipEnabled={true}
                                                                tooltipContent="(Cash Available / Operating Expenses – Depreciation Expenses) x 365"
                                                                disabled={true}
                                                                hideIcon={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <h5 className='text-uppercase f-600 mt-3 mb-2'>Borrowing entity</h5>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group AI_btn_field">
                                                            <FormTextarea
                                                                name="financialBorrowingCashflow"
                                                                label="Cash flow"
                                                                placeholder="Provide detail (optional)"
                                                                id="financialBorrowingCashflow"
                                                            />

                                                            <OpenAIComponent
                                                                fieldName="financialBorrowingCashflow"
                                                                formGroup={formData}
                                                                type="textarea"
                                                                AiType="AiUpdate"
                                                                loanId={loanid}
                                                                loanType={staticCreditMemoLoanType.find(type => type.value === formData.value.loanType)?.label}
                                                                ownerId={0}
                                                                businessOwnerName=""
                                                                action="BorrowingEntityCashFlow"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group AI_btn_field">
                                                            <FormTextarea
                                                                name="financialBorrowingReAssuarance"
                                                                placeholder="Provide detail (optional)"
                                                                id="financialBorrowingReAssuarance"
                                                                label="Reassurance of repayment"
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="financialBorrowingReAssuarance"
                                                                formGroup={formData}
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group AI_btn_field">
                                                            <FormTextarea
                                                                name="financialBorrowingProjection"
                                                                placeholder="Provide detail (optional)"
                                                                id="financialBorrowingProjection"
                                                                label="Projection"
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="financialBorrowingProjection"
                                                                formGroup={formData}
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group AI_btn_field">
                                                            <FormTextarea
                                                                name="financialBorrowingAdditional"
                                                                placeholder="Provide detail (optional)"
                                                                id="financialBorrowingAdditional"
                                                                label="Additional information"
                                                            />
                                                            <OpenAIComponent
                                                                fieldName="financialBorrowingAdditional"
                                                                formGroup={formData}
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <h5 className='text-uppercase f-600 mt-3 mb-3'>Affiliates</h5>
                                                <div className='row'>
                                                    <FormArrayComponent
                                                        control={formData.get("lstCreditMemoFinanicalsAffiliates")}
                                                        render={(affiliatesFinancials, index) => (
                                                            <div className='col-md-6'>
                                                                <div className="form-group AI_btn_field">
                                                                    <h5 className='d-flex text-uppercase text-dark mb-2 f-600'>
                                                                        <img width={14} className='mr-2' src={grid_company_icon} alt="" />
                                                                        {affiliatesFinancials.value.userName}
                                                                    </h5>
                                                                    <FormTextarea
                                                                        name={`lstCreditMemoFinanicalsAffiliates.${index}.remarks`}
                                                                        placeholder="Provide detail (optional)"
                                                                        id={`lstCreditMemoFinanicalsAffiliates.${index}.remarks`}
                                                                        hideLabel={true}
                                                                    />
                                                                    <OpenAIComponent
                                                                        fieldName={`lstCreditMemoFinanicalsAffiliates.${index}.remarks`}
                                                                        formGroup={formData}
                                                                        type="textarea"
                                                                        AiType="AiUpdate"
                                                                        showLeftSide={index % 2 !== 0 ? true : false}
                                                                        loanId={loanid}
                                                                        loanType={staticCreditMemoLoanType.find(type => type.value === formData.value.loanType)?.label}
                                                                        ownerId={affiliatesFinancials.value.userId}
                                                                        businessOwnerName={affiliatesFinancials.value.userName}
                                                                        action="BorrowingEntityCashFlow"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>

                                                <h5 className='text-uppercase f-600 mt-3 mb-3'>Guarantors</h5>
                                                <div className='row'>
                                                    <FormArrayComponent
                                                        control={formData.get("lstCreditMemoFinanicalsGuarantors")}
                                                        render={(guarantorFinancials, index) => (
                                                            <div className='col-md-6'>
                                                                <div className="form-group AI_btn_field">
                                                                    <h5 className='d-flex text-uppercase text-dark mb-2 f-600'>
                                                                        <img width={14} className='mr-2' src={grid_company_icon} alt="" />
                                                                        {guarantorFinancials.value.userName}
                                                                    </h5>
                                                                    <FormTextarea
                                                                        name={`lstCreditMemoFinanicalsGuarantors.${index}.remarks`}
                                                                        placeholder="Provide detail (optional)"
                                                                        id={`lstCreditMemoFinanicalsGuarantors.${index}.remarks`}
                                                                        hideLabel={true}
                                                                    />
                                                                    <OpenAIComponent
                                                                        fieldName={`lstCreditMemoFinanicalsGuarantors.${index}.remarks`}
                                                                        formGroup={formData}
                                                                        type="textarea"
                                                                        AiType="AiUpdate"
                                                                        showLeftSide={index % 2 !== 0 ? true : false}
                                                                        loanId={loanid}
                                                                        loanType={staticCreditMemoLoanType.find(type => type.value === formData.value.loanType)?.label}
                                                                        ownerId={guarantorFinancials.value.userId}
                                                                        businessOwnerName={guarantorFinancials.value.userName}
                                                                        action="GuarantorCashFlow"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading mb-3">Succession Plan</h3>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <FormRadio
                                                                name="successionPlanFormalized"
                                                                id="successionPlanFormalized"
                                                                label="Does the borrower have a formalized succession plan?"
                                                                options={[
                                                                    { value: "Yes", label: "Yes" },
                                                                    { value: "No", label: "No" }
                                                                ]}
                                                            />
                                                        </div>
                                                        {
                                                            formData.value.successionPlanFormalized === "Yes" && (
                                                                <div className="form-group AI_btn_field">
                                                                    <FormTextarea
                                                                        name="successionPlanFormalizedText"
                                                                        id="successionPlanFormalizedText"
                                                                        placeholder="Provide detail (optional)"
                                                                        hideLabel={true}
                                                                    />
                                                                    <OpenAIComponent
                                                                        fieldName="successionPlanFormalizedText"
                                                                        formGroup={formData}
                                                                        type="textarea"
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <FormRadio
                                                                name="successionPlanOperatinalManagement"
                                                                id="successionPlanOperatinalManagement"
                                                                label="Is there a third party responsible for operational management?"
                                                                options={[
                                                                    { value: "Yes", label: "Yes" },
                                                                    { value: "No", label: "No" }
                                                                ]}
                                                            />
                                                        </div>
                                                        {
                                                            formData.value.successionPlanOperatinalManagement === "Yes" && (
                                                                <div className="form-group AI_btn_field">
                                                                    <FormTextarea
                                                                        name="successionPlanOperatinalManagementText"
                                                                        placeholder="Provide detail (optional)"
                                                                        id="successionPlanOperatinalManagementText"
                                                                        hideLabel={true}
                                                                    />
                                                                    <OpenAIComponent
                                                                        fieldName="successionPlanOperatinalManagementText"
                                                                        formGroup={formData}
                                                                        type="textarea"
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group line-breaker">
                                                            <FormRadio
                                                                name="successionPlanPersonPolicy"
                                                                id="successionPlanPersonPolicy"
                                                                label="Does the borrower have a key person policy in place?"
                                                                tooltipEnabled={true}
                                                                tooltipContent="A key person insurance is a life insurance policy that protects a business from the financial loss of a key employee"
                                                                tooltipPosition="right"
                                                                options={[
                                                                    { value: "Yes", label: "Yes" },
                                                                    { value: "No", label: "No" }
                                                                ]}
                                                            />
                                                        </div>
                                                        {
                                                            formData.value.successionPlanPersonPolicy === "Yes" && (
                                                                <div className="form-group AI_btn_field">
                                                                    <FormTextarea
                                                                        name="successionPlanPersonPolicyText"
                                                                        placeholder="Provide detail (optional)"
                                                                        id="successionPlanPersonPolicyText"
                                                                        hideLabel={true}
                                                                    />
                                                                    <OpenAIComponent
                                                                        fieldName="successionPlanPersonPolicyText"
                                                                        formGroup={formData}
                                                                        type="textarea"
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="lm-card Banks_Exposure_Review_card">
                                                <h3 className="lm-card-heading mb-3">Bank's Exposure Review</h3>
                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoBankExposure")}
                                                    render={(bankExposureData, index) => (
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <h5 className='d-flex text-uppercase text-dark mb-3 f-600'>
                                                                    <img width={14} className='mr-2' src={grid_company_icon} alt="" />
                                                                    {bankExposureData.value.userName}
                                                                </h5>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group">
                                                                    <label className='d-flex' htmlFor="Risk_grade">Risk grade
                                                                        <div className="lm-tooltip ml-2" data-tooltip="1 is the least amount of risk and 5 is the most amount of risk."
                                                                            data-tooltip-pos="right" data-tooltip-length="medium"> <img src={Info_tooltip} alt="" />
                                                                        </div>
                                                                    </label>
                                                                    <Select
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        menuPortalTarget={document.body}
                                                                        styles={{
                                                                            menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                        }}
                                                                        options={RiskGradeDropdownOptions}
                                                                        value={RiskGradeDropdownOptions.find(type => type.value === bankExposureData.value.riskGrade)}
                                                                        onChange={(e) => {
                                                                            formData.patchValue({
                                                                                lstCreditMemoBankExposure: formData.value.lstCreditMemoBankExposure.map((item, i) => {
                                                                                    if (i === index) {
                                                                                        item.riskGrade = e.value
                                                                                    }
                                                                                    return item
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group">
                                                                    <FormRadio
                                                                        name={`lstCreditMemoBankExposure.${index}.customerHistory`}
                                                                        label="Customer history"
                                                                        options={[
                                                                            { value: "Yes", label: "New" },
                                                                            { value: "No", label: "Existing" }
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group input-date">
                                                                    <label htmlFor="Customer_since">Customer since</label>
                                                                    <FieldControl
                                                                        name={`lstCreditMemoBankExposure.${index}.customerSince`}
                                                                        render={({ handler, touched, hasError, meta }) => (
                                                                            <DatePicker
                                                                                showIcon
                                                                                toggleCalendarOnIconClick
                                                                                {...handler()}
                                                                                selected={handler().value ? moment(handler().value).toDate() : null}
                                                                                value={checkAndFormatDate(handler().value)}
                                                                                isClearable={handler().value}
                                                                                dateFormat="MMM d, yyyy"
                                                                                placeholderText="Month DD, YYYY"
                                                                                yearDropdownItemNumber={100}
                                                                                scrollableYearDropdown
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                onChange={(date) => {
                                                                                    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                                    handler().onChange(formattedDate);
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    e.preventDefault();
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group">
                                                                    <FormField
                                                                        name={`lstCreditMemoBankExposure.${index}.approvalRequired`}
                                                                        id={`lstCreditMemoBankExposure.${index}.approvalRequired`}
                                                                        label="Approval required"
                                                                        placeholder="Enter approval required"
                                                                        tooltipEnabled={true}
                                                                        tooltipContent="Example: senior credit manager and / or credit committee."
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group input-dollar">
                                                                    <AmountContainer
                                                                        name={`lstCreditMemoBankExposure.${index}.currentBalance`}
                                                                        id={`lstCreditMemoBankExposure.${index}.currentBalance`}
                                                                        label="Current balance"
                                                                        placeholder="0.00"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group input-dollar">
                                                                    <AmountContainer
                                                                        name={`lstCreditMemoBankExposure.${index}.availableCredit`}
                                                                        id={`lstCreditMemoBankExposure.${index}.availableCredit`}
                                                                        label="Available credit"
                                                                        placeholder="0.00"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group input-dollar">
                                                                    <AmountContainer
                                                                        name={`lstCreditMemoBankExposure.${index}.currentRequest`}
                                                                        id={`lstCreditMemoBankExposure.${index}.currentRequest`}
                                                                        label="Current request"
                                                                        placeholder="0.00"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-lg-3">
                                                                <div className="form-group input-dollar">
                                                                    <AmountContainer
                                                                        name={`lstCreditMemoBankExposure.${index}.totalExposure`}
                                                                        id={`lstCreditMemoBankExposure.${index}.totalExposure`}
                                                                        label="Total explosure"
                                                                        placeholder="0.00"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <!-- lm common divider to break the section  --> */}
                                                            <div className="lm-divider my-3 mx-3"></div>
                                                            {/* <!-- lm common divider to break the section  --> */}
                                                        </div>
                                                    )}
                                                />
                                            </div>

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading mb-3">Strengths & Weaknesses</h3>

                                                <h5 className='text-uppercase f-600 mt-3 mb-3'>strengths</h5>
                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoStrength")}
                                                    render={(strengthsData, index) => (
                                                        strengthsData.value.isDeleted === 0 && (
                                                            <div className='row' >
                                                                <div className="col-md-3 col-lg-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="Category">Category</label>
                                                                        <Select
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            options={staticCreditMemoStrengthWeak}
                                                                            value={staticCreditMemoStrengthWeak.find(type => type.value === strengthsData.value.categoryId)}
                                                                            onChange={(e) => {
                                                                                formData.get('lstCreditMemoStrength').controls[index].get('categoryId').setValue(e.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-8 col-lg-8">
                                                                    <div style={{ paddingTop: 3 }} className="form-group AI_btn_field">
                                                                        <FormField
                                                                            name={`lstCreditMemoStrength.${index}.details`}
                                                                            id={`lstCreditMemoStrength.${index}.details`}
                                                                            placeholder="Provide detail"
                                                                            maxlength={250}
                                                                        />
                                                                        <OpenAIComponent
                                                                            fieldName={`lstCreditMemoStrength.${index}.details`}
                                                                            formGroup={formData}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1 col-lg-1 d-flex mt-4 pt-2">
                                                                    {index !== 0 && <div><img className="cursor-pointer" src={feild_minus_icon} alt="minus" onClick={() => removeCheckings(index, formData, 'lstCreditMemoStrength')} /></div>}
                                                                    <div className='ml-2'><img className="cursor-pointer" src={feild_plus_icon} alt="plus" onClick={() => addCheckings({ formGroup: formData, keyName: "lstCreditMemoStrength", dispatch, type: "Strength" })} /></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                />

                                                <h5 className='text-uppercase f-600 mt-3 mb-3'>Weaknesses</h5>
                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoWeakness")}
                                                    render={(strengthsData, index) => (
                                                        strengthsData.value.isDeleted === 0 && (
                                                            <div className='row' >
                                                                <div className="col-md-3 col-lg-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="Category">Category</label>
                                                                        <Select
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            menuPortalTarget={document.body}
                                                                            styles={{
                                                                                menuPortal: base => ({ ...base, zIndex: 9999 })
                                                                            }}
                                                                            options={staticCreditMemoStrengthWeak}
                                                                            value={staticCreditMemoStrengthWeak.find(type => type.value === strengthsData.value.categoryId)}
                                                                            onChange={(e) => {
                                                                                formData.get('lstCreditMemoWeakness').controls[index].get('categoryId').setValue(e.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-8 col-lg-8">
                                                                    <div style={{ paddingTop: 3 }} className="form-group AI_btn_field">
                                                                        <FormField
                                                                            name={`lstCreditMemoWeakness.${index}.details`}
                                                                            id={`lstCreditMemoWeakness.${index}.details`}
                                                                            placeholder="Provide detail"
                                                                            maxlength={250}
                                                                        />
                                                                        <OpenAIComponent
                                                                            fieldName={`lstCreditMemoWeakness.${index}.details`}
                                                                            formGroup={formData}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1 col-lg-1 d-flex mt-4 pt-2">
                                                                    {index !== 0 && <div><img className="cursor-pointer" src={feild_minus_icon} alt="minus" onClick={() => removeCheckings(index, formData, 'lstCreditMemoWeakness')} /></div>}
                                                                    <div className='ml-2'><img className="cursor-pointer" src={feild_plus_icon} alt="plus" onClick={() => addCheckings({ formGroup: formData, keyName: "lstCreditMemoWeakness", dispatch, type: "Weakness" })} /></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                />

                                            </div>

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading mb-3">Key Risks & Mitigants</h3>

                                                <h5 className='text-uppercase f-600 mt-3 mb-2'>Risks</h5>
                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoKeyRisk")}
                                                    render={(strengthsData, index) => (
                                                        strengthsData.value.isDeleted === 0 && (
                                                            <div className='row' >
                                                                <div className="col-md-11 col-lg-11">
                                                                    <div className="form-group AI_btn_field">
                                                                        <FormField
                                                                            name={`lstCreditMemoKeyRisk.${index}.details`}
                                                                            id={`lstCreditMemoKeyRisk.${index}.details`}
                                                                            placeholder="Provide detail"
                                                                            hideLabel={true}
                                                                            maxlength={250}
                                                                        />
                                                                        <OpenAIComponent
                                                                            fieldName={`lstCreditMemoKeyRisk.${index}.details`}
                                                                            formGroup={formData}
                                                                            hiddenLable={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1 col-lg-1 d-flex mt-1">
                                                                    {index !== 0 && <div><img className="cursor-pointer" src={feild_minus_icon} alt="minus" onClick={() => removeCheckings(index, formData, 'lstCreditMemoKeyRisk')} /></div>}
                                                                    <div className='ml-2'><img className="cursor-pointer" src={feild_plus_icon} alt="plus" onClick={() => addCheckings({ formGroup: formData, keyName: "lstCreditMemoKeyRisk", dispatch, type: "Key Risk" })} /></div>
                                                                </div>

                                                            </div>
                                                        )
                                                    )}
                                                />

                                                <h5 className='text-uppercase f-600 mt-3 mb-2'>Mitigants</h5>
                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoMitigants")}
                                                    render={(strengthsData, index) => (
                                                        strengthsData.value.isDeleted === 0 && (
                                                            <div className='row' >
                                                                <div className="col-md-11 col-lg-11">
                                                                    <div className="form-group AI_btn_field">
                                                                        <FormField
                                                                            name={`lstCreditMemoMitigants.${index}.details`}
                                                                            id={`lstCreditMemoMitigants.${index}.details`}
                                                                            placeholder="Provide detail"
                                                                            hideLabel={true}
                                                                            maxlength={250}
                                                                        />
                                                                        <OpenAIComponent
                                                                            fieldName={`lstCreditMemoMitigants.${index}.details`}
                                                                            formGroup={formData}
                                                                            hiddenLable={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1 col-lg-1 d-flex mt-1">
                                                                    {index !== 0 && <div><img className="cursor-pointer" src={feild_minus_icon} alt="minus" onClick={() => removeCheckings(index, formData, 'lstCreditMemoMitigants')} /></div>}
                                                                    <div className='ml-2'><img className="cursor-pointer" src={feild_plus_icon} alt="plus" onClick={() => addCheckings({ formGroup: formData, keyName: "lstCreditMemoMitigants", dispatch, type: "Mitigants" })} /></div>
                                                                </div>

                                                            </div>
                                                        )
                                                    )}
                                                />
                                            </div>

                                            <div className="lm-card">
                                                <h3 className="lm-card-heading mb-3">Lending Conditions & Exceptions</h3>

                                                <h5 className='text-uppercase f-600 mt-3 mb-2'>Conditions</h5>
                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoCondition")}
                                                    render={(strengthsData, index) => (
                                                        strengthsData.value.isDeleted === 0 && (
                                                            <div className='row' >
                                                                <div className="col-md-11 col-lg-11">
                                                                    <div className="form-group AI_btn_field">
                                                                        <FormField
                                                                            name={`lstCreditMemoCondition.${index}.details`}
                                                                            id={`lstCreditMemoCondition.${index}.details`}
                                                                            placeholder="Provide detail"
                                                                            hideLabel={true}
                                                                            maxlength={250}
                                                                        />
                                                                        <OpenAIComponent
                                                                            fieldName={`lstCreditMemoCondition.${index}.details`}
                                                                            formGroup={formData}
                                                                            hiddenLable={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1 col-lg-1 d-flex mt-1">
                                                                    {index !== 0 && <div><img className="cursor-pointer" src={feild_minus_icon} alt="minus" onClick={() => removeCheckings(index, formData, 'lstCreditMemoCondition')} /></div>}
                                                                    <div className='ml-2'><img className="cursor-pointer" src={feild_plus_icon} alt="plus" onClick={() => addCheckings({ formGroup: formData, keyName: "lstCreditMemoCondition", dispatch, type: "Condition" })} /></div>
                                                                </div>

                                                            </div>
                                                        )
                                                    )}
                                                />

                                                <h5 className='text-uppercase f-600 mt-3 mb-2'>Exceptions</h5>
                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoException")}
                                                    render={(strengthsData, index) => (
                                                        strengthsData.value.isDeleted === 0 && (
                                                            <div className='row' >
                                                                <div className="col-md-11 col-lg-11">
                                                                    <div className="form-group AI_btn_field">
                                                                        <FormField
                                                                            name={`lstCreditMemoException.${index}.details`}
                                                                            id={`lstCreditMemoException.${index}.details`}
                                                                            placeholder="Provide detail"
                                                                            hideLabel={true}
                                                                            maxlength={250}
                                                                        />
                                                                        <OpenAIComponent
                                                                            fieldName={`lstCreditMemoException.${index}.details`}
                                                                            formGroup={formData}
                                                                            hiddenLable={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1 col-lg-1 d-flex mt-1">
                                                                    {index !== 0 && <div><img className="cursor-pointer" src={feild_minus_icon} alt="minus" onClick={() => removeCheckings(index, formData, 'lstCreditMemoException')} /></div>}
                                                                    <div className='ml-2'><img className="cursor-pointer" src={feild_plus_icon} alt="plus" onClick={() => addCheckings({ formGroup: formData, keyName: "lstCreditMemoException", dispatch, type: "Exception" })} /></div>
                                                                </div>

                                                            </div>
                                                        )
                                                    )}
                                                />
                                            </div>

                                            <div className="pfs-card lm-card p-0">
                                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                                    <h3 className="lm-card-heading mb-0">Third Party Reports</h3>
                                                    <a className="lm-button3"
                                                        onClick={() => {
                                                            setThirdPartyReportType("Add");
                                                            thirdPartyReportFormGroup.patchValue({
                                                                id: 0,
                                                                creditMemoId: 0,
                                                                reportType: "",
                                                                reportDate: "",
                                                                vendorName: "",
                                                                comment: "",
                                                                isDeleted: 0,
                                                                index: 0
                                                            })
                                                            setAddReportPopup(true);
                                                        }}
                                                    ><span>+</span>Add report</a>
                                                </div>

                                                <FormArrayComponent
                                                    control={formData.get("lstCreditMemoThirdPartyReport")}
                                                    render={(thirdPartyData, index) => (
                                                        thirdPartyData.value.isDeleted === 0 && (
                                                            <div className="row p-3 pfs-data-loop-row">
                                                                <div className="col-md-4 mb-3">
                                                                    <div className="pfs-card-label">Report type</div>
                                                                    <div className="pfs-card-value">{thirdPartyData.value.reportType}</div>
                                                                </div>
                                                                <div className="col-md-4 mb-3">
                                                                    <div className="pfs-card-label">Report date</div>
                                                                    <div className="pfs-card-value">{checkAndFormatDate(thirdPartyData.value.reportDate)}</div>
                                                                </div>
                                                                <div className="col-md-4 mb-3">
                                                                    <div className="pfs-card-label">Vendor</div>
                                                                    <div className="pfs-card-value">{thirdPartyData.value.vendorName}</div>
                                                                </div>
                                                                <div className="col-md-12 mb-3">
                                                                    <div className="pfs-card-label">Comment</div>
                                                                    <div className="pfs-card-value">{thirdPartyData.value.comment}</div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <button type="button" className="lm-button2 w-100" onClick={() => {
                                                                        setThirdPartyReportType("Edit");
                                                                        thirdPartyReportFormGroup.reset(thirdPartyData.value);
                                                                        thirdPartyReportFormGroup.patchValue({ index });
                                                                        setAddReportPopup(true);
                                                                    }}
                                                                    >
                                                                        <img className='mr-2' src={lm_edit_icon} alt="" />
                                                                        Edit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                />
                                            </div>

                                            <div className="sticky-btn-footer d-flex justify-content-end">
                                                <button type="button" className="lm-button1 ml-3" onClick={handleSubmitCreditMemoOverview}>Save</button>
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        </div>


                        <div className={`tab-pane lm-form-box  fade ${activeTab === "Memo" ? "show active" : ""}`} id="Memo">
                            <div className="pfs-card lm-card p-0">
                                <div className="row px-3 pt-3">
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Loan Officer</div>
                                        <div className="pfs-card-value">{memoData?.loanOfficer || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Bank</div>
                                        <div className="pfs-card-value">{memoData?.bank || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Risk Grade</div>
                                        <div className="pfs-card-value">{memoData?.riskGrade && memoData.riskGrade !== "0" ? memoData.riskGrade : '-' || '-'}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Customer Since</div>
                                        <div className="pfs-card-value">{memoData?.customerSince ? moment(memoData?.customerSince).format("MMM DD, YYYY") : "" || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Approval Required</div>
                                        <div className="pfs-card-value">{memoData?.approvalRequired || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Borrower(s)</div>
                                        <ul className='memo-lm-list'>
                                            <li><span>EPC</span>{memoData.epc || "-"}</li>
                                            <li><span>OC</span>{memoData.oc || "-"}</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Owners / Members</div>
                                        <div className="pfs-card-value">{memoData.lstCreditMemoEPCOC?.EPC.name || "-"}</div>
                                        <ul className='memo-lm-list'>
                                            {
                                                memoData.lstCreditMemoEPCOC?.EPC.name && memoData.lstCreditMemoEPCOC?.EPC.hasOwnProperty('ownersList') && memoData.lstCreditMemoEPCOC?.EPC.ownersList.map((owner, index) => (
                                                    <li key={index}>{owner}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Owners / Members</div>
                                        <div className="pfs-card-value">{memoData.lstCreditMemoEPCOC?.OC.name}</div>
                                        {
                                            memoData.lstCreditMemoEPCOC?.OC.name !== "-" &&
                                            <ul className='memo-lm-list'>
                                                {
                                                    memoData.lstCreditMemoEPCOC?.OC.name && memoData.lstCreditMemoEPCOC?.EPC.hasOwnProperty('ownersList') && memoData.lstCreditMemoEPCOC?.OC.ownersList.map((owner, index) => (
                                                        <li key={index}>{owner}</li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Guarantor (FICO)</div>
                                        <div className="pfs-card-value">{memoData.lstCreditMemoGuarantor?.name || "-"}</div>
                                        <ul className='memo-lm-list'>
                                            {
                                                memoData.lstCreditMemoGuarantor && memoData.lstCreditMemoGuarantor.ownersList.map((owner, index) => (
                                                    <li key={index}>{owner}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">NAICS code</div>
                                        <div className="pfs-card-value">{memoData?.naicScode || "-"}</div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="pfs-card-label">Industry</div>
                                        <div className="pfs-card-value">{memoData?.industry && memoData?.industry !== "undefined" ? memoData?.industry : "-" || '-'}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pfs-card lm-card p-0">
                                <div className="row px-3 pt-3">
                                    <div className="col-md-12 mb-3">
                                        <div className="pfs-card-label">Request</div>
                                        <div className="pfs-card-value">{memoData?.request || "-"}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pfs-card lm-card p-0">
                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                    <h3 className="lm-card-heading mb-0">Borrower Information</h3>
                                </div>
                                <div className="row px-3 pt-3 pfs-data-loop-row">
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Borrower</div>
                                        <div className="pfs-card-value d-flex">
                                            <img width={14} className='mr-2' src={grid_company_icon} alt="" />
                                            {memoData?.borrower || "-"}
                                        </div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">TIN/SSN</div>
                                        <div className="pfs-card-value">{memoData?.tiN_SSN || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Entity Type</div>
                                        <div className="pfs-card-value">{memoData?.entityType || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">PHONE</div>
                                        <div className="pfs-card-value">{memoData?.phone || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">email</div>
                                        <div className="pfs-card-value">{memoData?.email || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">New/Existing Customer</div>
                                        <div className="pfs-card-value">{memoData?.customerHistory || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Address</div>
                                        <div className="pfs-card-value">{memoData?.address || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Gross Annual Revenue</div>
                                        <div className="pfs-card-value">{memoData?.grossAnnualRevenue ? "$" + formatNumberWithCommas(memoData?.grossAnnualRevenue) : '-' || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Source Document</div>
                                        <div className="pfs-card-value">{memoData?.sourceDocument || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Date of Source</div>
                                        <div className="pfs-card-value">{memoData?.dateofSource || "-"}</div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <div className="pfs-card-label">Benefits of proceeds</div>
                                        <div className="pfs-card-value">{memoData?.benefitsofproceeds || "-"}</div>
                                    </div>
                                </div>
                                <div className="pfs-card-head d-flex justify-content-between bg-grey py-3">
                                    <span className="label text-uppercase text-dark mb-0"><b>Affiliates</b></span>
                                </div>
                                {
                                    memoData.hasOwnProperty('lstCreditMemoAffiliate') && memoData?.lstCreditMemoAffiliate.map((affiliate, index) => (
                                        <div className='row px-3 pt-3'>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Business name</div>
                                                <div className="pfs-card-value d-flex">
                                                    <img width={14} className='mr-2' src={grid_company_icon} alt="" />
                                                    {affiliate.businessName || "-"}
                                                </div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Industry</div>
                                                <div className="pfs-card-value">{affiliate.industry || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Inception date</div>
                                                <div className="pfs-card-value">{affiliate.inceptiondate || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Owners / Members</div>
                                                {
                                                    affiliate.ownersList.length > 0 ?
                                                        <ul className='memo-lm-list'>
                                                            {
                                                                affiliate.ownersList.map((owner, index) => (
                                                                    <li key={index}>{owner}</li>
                                                                ))
                                                            }
                                                        </ul> : "-"
                                                }

                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">address</div>
                                                <div className="pfs-card-value">{affiliate.address || "-"}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="pfs-card-head d-flex justify-content-between bg-grey py-3">
                                    <span className="label text-uppercase text-dark mb-0"><b>Loan Information</b></span>
                                </div>
                                <div className='row px-3 pt-3 pfs-data-loop-row'>
                                    <div className="col-lg-7 mr-sm-3 mr-0 col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Purpose</div>
                                        <div className="pfs-card-value">{memoData?.purpose || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Loan Type</div>
                                        <div className="pfs-card-value">{memoData?.loanType || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Amount</div>
                                        <div className="pfs-card-value">{memoData?.amount ? "$" + formatNumberWithCommas(memoData?.amount) : "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Rate</div>
                                        <div className="pfs-card-value">{memoData.rate !== "-" ? memoData.rate + "%" : "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Loan term</div>
                                        <div className="pfs-card-value">{memoData?.loanterm || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Payment Amount</div>
                                        <div className="pfs-card-value">{memoData?.paymentAmount ? "$" + formatNumberWithCommas(memoData?.paymentAmount) : "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Payment Type</div>
                                        <div className="pfs-card-value">{memoData?.paymentType || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Index</div>
                                        <div className="pfs-card-value">{memoData?.index || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Spread</div>
                                        <div className="pfs-card-value">{memoData.spread !== "" ? memoData.spread + "%" : "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Rate Change Frequency</div>
                                        <div className="pfs-card-value">{memoData?.rateChangeFrequency || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Fee(s)</div>
                                        <ul className='memo-lm-list'>
                                            {
                                                memoData.hasOwnProperty('lstCreditMemoFee') && memoData.lstCreditMemoFee.map((owner, index) => (
                                                    <li key={index}>{owner.keyName + " - $" + formatNumberWithCommas(owner.keyValue)}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Repayment Source</div>
                                        <div className="pfs-card-value">{memoData?.repaymentSource || "-"}</div>
                                    </div>
                                </div>
                                <div className='row px-3 pt-3 pfs-data-loop-row'>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">type (Collateral)</div>
                                        <div className="pfs-card-value">{memoData?.collateralType || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Location (Collateral)</div>
                                        <div className="pfs-card-value">{memoData?.location_Collateral || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Value</div>
                                        <div className="pfs-card-value">{memoData?.collateralAppaisalValue ? "$" + formatNumberWithCommas(memoData?.collateralAppaisalValue) : "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">LTV</div>
                                        <div className="pfs-card-value">{memoData.ltv !== "-" ? memoData.ltv + "%" : "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">Occupancy</div>
                                        <div className="pfs-card-value">{memoData?.occupancy || "-"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                        <div className="pfs-card-label">New</div>
                                        <div className="pfs-card-value">{memoData?.collateralNew || "-"}</div>
                                    </div>
                                </div>
                                <div className="pfs-card-head d-flex justify-content-between bg-grey py-3">
                                    <span className="label text-uppercase text-dark mb-0"><b>guarantor's experience</b></span>
                                </div>
                                {
                                    memoData.hasOwnProperty('lstCreditMemoBusinessExperience') && memoData?.lstCreditMemoBusinessExperience.map((guarantorExperience, index) => (
                                        <div className='row px-3 pt-3 pfs-data-loop-row'>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Company name</div>
                                                <div className="pfs-card-value">{guarantorExperience.companyname || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Title</div>
                                                <div className="pfs-card-value">{guarantorExperience.title || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Period </div>
                                                <div className="pfs-card-value">{guarantorExperience.period || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">location</div>
                                                <div className="pfs-card-value">{guarantorExperience.address || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">summary</div>
                                                <div className="pfs-card-value">{guarantorExperience.summary || "-"}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="pfs-card-head d-flex justify-content-between bg-grey py-3">
                                    <span className="label text-uppercase text-dark mb-0"><b>guarantor's education</b></span>
                                </div>
                                {
                                    memoData.hasOwnProperty('lstCreditMemoEducation') && memoData?.lstCreditMemoEducation.map((guarantorEducation, index) => (
                                        <div className='row px-3 pt-3 pfs-data-loop-row'>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Institution name</div>
                                                <div className="pfs-card-value">{guarantorEducation.institutionName || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Period</div>
                                                <div className="pfs-card-value">{guarantorEducation.period || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">location </div>
                                                <div className="pfs-card-value">{guarantorEducation.location || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Major</div>
                                                <div className="pfs-card-value">{guarantorEducation.major || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                <div className="pfs-card-label">Degree or Certificate</div>
                                                <div className="pfs-card-value">{guarantorEducation.degreeCertificate || "-"}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="pfs-card lm-card p-0">
                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                    <h3 className="lm-card-heading mb-0">Third Party Reports</h3>
                                </div>
                                {
                                    memoData.hasOwnProperty('lstCreditMemoThirdPartyReport') && memoData?.lstCreditMemoThirdPartyReport.map((thirdPartyReport, index) => (
                                        <div className="row p-3 pfs-data-loop-row">
                                            <div className="col-md-2 mb-3">
                                                <div className="pfs-card-label">report type</div>
                                                <div className="pfs-card-value">{thirdPartyReport.reportType || "-"}</div>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className="pfs-card-label">Report date</div>
                                                <div className="pfs-card-value">{thirdPartyReport.reportDate || "-"}</div>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className="pfs-card-label">Vendor</div>
                                                <div className="pfs-card-value">{thirdPartyReport.vendorName || "-"}</div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className="pfs-card-label">Comment</div>
                                                <div className="pfs-card-value">{thirdPartyReport.comment || "-"}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="pfs-card lm-card p-0">
                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                    <h3 className="lm-card-heading mb-0">Bank's Exposure Review</h3>
                                </div>
                                <div className="row px-3">
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="pfs-card-label mb-2">Borrower</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="pfs-card-label mb-2">Current Balance</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="pfs-card-label mb-2">Available Credit</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="pfs-card-label mb-2">Current Request</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="pfs-card-label mb-2">Total Exposure</div>
                                    </div>
                                </div>
                                {
                                    memoData.hasOwnProperty('lstCreditMemoBankExposure') && memoData?.lstCreditMemoBankExposure.map((bankExposure, index) => (
                                        <div className="row px-3">
                                            <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                                <div className="f-600 pfs-card-value mb-2">{bankExposure.userName || "-"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                                <div className="f-600 pfs-card-value mb-2">${formatNumberWithCommas(toFixedOrZero(getValue(bankExposure.currentBalance))) || "0.00"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                                <div className="f-600 pfs-card-value mb-2">${formatNumberWithCommas(toFixedOrZero(getValue(bankExposure.availableCredit))) || "0.00"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                                <div className="f-600 pfs-card-value mb-2">${formatNumberWithCommas(toFixedOrZero(getValue(bankExposure.currentRequest))) || "0.00"}</div>
                                            </div>
                                            <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                                <div className="f-600 pfs-card-value mb-2">${formatNumberWithCommas(toFixedOrZero(getValue(bankExposure.totalExposure))) || "0.00"}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="row px-3 mb-3">
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="f-700 pfs-card-value">Total</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="f-700 pfs-card-value">${formatNumberWithCommas(bankExposureTotal.CB) || "0.00"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="f-700 pfs-card-value">${formatNumberWithCommas(bankExposureTotal.AC) || "0.00"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="f-700 pfs-card-value">${formatNumberWithCommas(bankExposureTotal.CR) || "0.00"}</div>
                                    </div>
                                    <div className="col-lg-fifth col-md-4 col-6 mb-2">
                                        <div className="f-700 pfs-card-value">${formatNumberWithCommas(bankExposureTotal.TE) || "0.00"}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pfs-card lm-card p-0 pb-3">
                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                    <h3 className="lm-card-heading mb-0">Succession Plan</h3>
                                </div>
                                <div className="row px-3 pb-2 mb-3 mb-sm-0">
                                    <div className='col-md-3 mb-3 mb-sm-0'>
                                        <div className="pfs-card-label">Formalized Plan</div>
                                        <div className="pfs-card-value">
                                            <img src={memoData?.successionPlanFormalized === "True" ? pfs_card_tick : pfs_card_cross} alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        <div className="pfs-card-label">Comments</div>
                                        <div className="pfs-card-value">{memoData?.successionPlanFormalizedText || "-"}</div>
                                    </div>
                                </div>
                                <div className="row px-3 pb-2 mb-3 mb-sm-0">
                                    <div className='col-md-3 mb-3 mb-sm-0'>
                                        <div className="pfs-card-label">Management Team</div>
                                        <div className="pfs-card-value">
                                            <img src={memoData?.successionPlanOperatinalManagement === "True" ? pfs_card_tick : pfs_card_cross} alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        <div className="pfs-card-label">Comments</div>
                                        <div className="pfs-card-value">{memoData?.successionPlanOperatinalManagementText || "-"}</div>
                                    </div>
                                </div>
                                <div className="row px-3 pb-2 mb-3 mb-sm-0">
                                    <div className='col-md-3 mb-3 mb-sm-0'>
                                        <div className="pfs-card-label">Insurance</div>
                                        <div className="pfs-card-value">
                                            <img src={memoData?.successionPlanPersonPolicy === "True" ? pfs_card_tick : pfs_card_cross} alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        <div className="pfs-card-label">Comments</div>
                                        <div className="pfs-card-value">{memoData?.successionPlanPersonPolicyText || "-"}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pfs-card lm-card p-0">
                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                    <h3 className="lm-card-heading mb-0">Competitive Analysis</h3>
                                </div>
                                <div className="pfs-card-head d-flex justify-content-between bg-grey py-3">
                                    <span className="label text-uppercase text-dark mb-0"><b>Industry</b></span>
                                </div>
                                <div className='row px-3 pt-3'>
                                    <div className="col-12 mb-3">
                                        <div className="pfs-card-value">
                                            {memoData?.industryAnalysis || "-"}
                                        </div>
                                    </div>
                                </div>
                                <div className="pfs-card-head d-flex justify-content-between bg-grey py-3">
                                    <span className="label text-uppercase text-dark mb-0"><b>Market</b></span>
                                </div>
                                <div className='row px-3 pt-3'>
                                    <div className="col-12 mb-3">
                                        <div className="pfs-card-value">
                                            {memoData?.marketAnalysis || "-"}
                                        </div>
                                    </div>
                                </div>
                                <div className="pfs-card-head d-flex justify-content-between bg-grey py-3">
                                    <span className="label text-uppercase text-dark mb-0"><b>Franchise</b></span>
                                </div>
                                <div className='row px-3 pt-3'>
                                    <div className="col-12 mb-3">
                                        <div className="pfs-card-value">
                                            {memoData?.franchiseAnalysis || "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pfs-card lm-card p-0">
                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                    <h3 className="lm-card-heading mb-0">Strengths & Weaknesses</h3>
                                </div>
                                <div className="row p-3 pfs-data-loop-row">
                                    <div className='col-md-2'>
                                        <div className="pfs-card-label">Category</div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="pfs-card-value text-green d-flex mb-3 mb-sm-0">
                                            <img className='mr-2' src={pfs_card_tick_DG} alt="" />
                                            STRENGTH
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="pfs-card-value text-red d-flex mb-3 mb-sm-0">
                                            <img className='mr-2' src={pfs_card_cross} alt="" />
                                            WEAKNESS
                                        </div>
                                    </div>
                                </div>
                                {
                                    memoData.hasOwnProperty('lstCreditMemoStrengthWeaknessReport') && memoData.lstCreditMemoStrengthWeaknessReport.map((item, index) => (
                                        <div className="row p-3 pfs-data-loop-row">
                                            <div className='col-md-2'>
                                                <div className="pfs-card-value">{item.category}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="pfs-card-value f-500">{item.strength}</div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="pfs-card-value f-500">{item.weakness}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="pfs-card lm-card p-0">
                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                    <h3 className="lm-card-heading mb-0">Key Risks & Mitigants</h3>
                                </div>
                                <div className="row p-3 pfs-data-loop-row">
                                    <div className='col-md-6'>
                                        <div className="pfs-card-value text-red d-flex mb-3 mb-sm-0">
                                            <img className='mr-2' src={pfs_card_cross} alt="" />
                                            RISKS
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="pfs-card-value text-green d-flex mb-3 mb-sm-0">
                                            <img className='mr-2' src={pfs_card_tick_DG} alt="" />
                                            MITIGANTS
                                        </div>
                                    </div>
                                </div>

                                {
                                    memoData.hasOwnProperty('lstCreditMemoKeysMitigantKeyRisk') && memoData?.lstCreditMemoKeysMitigantKeyRisk.map((item, index) => (
                                        <div className="row p-3 pfs-data-loop-row">
                                            <div className='col-md-6'>
                                                <div className="pfs-card-value f-500">
                                                    <ul className='memo-lm-list red-list'>
                                                        <li key={index}>{item['Key Risk']}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="pfs-card-value f-500">
                                                    <ul className='memo-lm-list green-list'>
                                                        <li key={index}>{item['Mitigants']}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>


                            <div className="pfs-card lm-card p-0">
                                <div className="pfs-card-head d-flex justify-content-between bg-white">
                                    <h3 className="lm-card-heading mb-0">Lending Conditions & Exceptions</h3>
                                </div>
                                <div className="row p-3 pfs-data-loop-row">
                                    <div className='col-md-6'>
                                        <div className="pfs-card-label">
                                            Conditions
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="pfs-card-label">
                                            Exceptions
                                        </div>
                                    </div>
                                </div>
                                {
                                    memoData.hasOwnProperty('lstCreditMemoKeysConditionException') && memoData?.lstCreditMemoKeysConditionException.map((item, index) => (
                                        <div className="row p-3 pfs-data-loop-row">
                                            <div className='col-md-6'>
                                                <div className="pfs-card-value f-600">
                                                    <ul className='memo-lm-list'>
                                                        <li key={index}>{item.Condition}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="pfs-card-value f-600">
                                                    <ul className='memo-lm-list'>
                                                        <li key={index}>{item.Exception}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>



                    {/* <!--Update Collateral Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={UpdateCollateralPopupShow}
                        onHide={() => setUpdateCollateralPopup(false)}
                        aria-labelledby="UpdateCollateralPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        By clicking update you will populate or replace the following fields with data collected from prior sections.  Do you want to proceed?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={UpdateCollateralPopupClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleConfirmGenerateAI}>Update</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--Update Collateral Popup / Modal --> */}

                    {/* <!--add fee Popup / Modal --> */}
                    <Modal
                        className='sendEmailPopup'
                        show={addFeeePopupShow}
                        onHide={addFeeePopupClose}
                        aria-labelledby="FeeePopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="FeeePopupLabel">{selectedFeeDataIndex !== "" ? "Edit" : "Additional"} fee</h5>
                                    <button type="button" className="close" onClick={addFeeePopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group mb-0">
                                            <label htmlFor="Additional_Fee">Fee</label>
                                            <input
                                                placeholder='Enter fee name'
                                                type="text"
                                                className="form-control"
                                                id="additionalFeeFieldValue"
                                                value={additionalFeeFieldValue}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Allow only alphanumeric characters (letters & numbers) with max length of 50
                                                    if (/^[a-zA-Z0-9 ]*$/.test(value) && value.length <= 50) {
                                                        setAdditionalFeeFieldValue(value);
                                                    }
                                                }}
                                                maxLength={50}
                                            />
                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        {
                                            selectedFeeDataIndex !== "" ? (
                                                <>
                                                    <button type="button" className="lm-button2" onClick={handleRemoveAdditionalFeed}>Remove</button>
                                                    <button type="button" className="lm-button2 text-red" onClick={handleSubmitAdditonalFeed}>Update</button>
                                                </>
                                            ) : (
                                                <>
                                                    <button type="button" className="lm-button2" onClick={addFeeePopupClose}>Cancel</button>
                                                    <button type="button" className="lm-button1" onClick={handleSubmitAdditonalFeed}>Add</button>
                                                </>
                                            )
                                        }
                                    </div>
                                </form>
                            </div>
                        </div >
                    </Modal >
                    {/* <!--add fee Popup / Modal --> */}

                    {/* <!--add Third Party Report Popup / Modal --> */}
                    <Modal
                        size="md"
                        show={addReportPopupShow}
                        onHide={addReportPopupClose}
                        aria-labelledby="ReportPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="ReportPopupLabel">{thirdPartyReportType} Third Party Report</h5>
                                    <button type="button" className="close" onClick={addReportPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <FieldGroup
                                    control={thirdPartyReportFormGroup}
                                    render={({ get, invalid }) => (
                                        <form>
                                            <Modal.Body>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <FormField
                                                                name="reportType"
                                                                id="reportType"
                                                                label="Report type"
                                                                placeholder="Enter Report type"
                                                                required={true}
                                                                errorMessage="Report type is required"
                                                                maxlength={50}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group input-date">
                                                            <label htmlFor="Report_date">Report date</label>
                                                            <DatePicker
                                                                showIcon
                                                                toggleCalendarOnIconClick
                                                                selected={thirdPartyReportFormGroup.value.reportDate ? moment(thirdPartyReportFormGroup.value.reportDate).toDate() : ""}
                                                                value={thirdPartyReportFormGroup.value.reportDate ? moment(thirdPartyReportFormGroup.value.reportDate).format("ll") : ""}
                                                                isClearable={thirdPartyReportFormGroup.value.reportDate}
                                                                dateFormat="MMM d, yyyy"
                                                                placeholderText="Month DD, YYYY"
                                                                yearDropdownItemNumber={100}
                                                                scrollableYearDropdown
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                onChange={(date) => {
                                                                    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
                                                                    thirdPartyReportFormGroup.patchValue({ reportDate: formattedDate })
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <FormField
                                                                name="vendorName"
                                                                id="vendorName"
                                                                label="Vendor"
                                                                placeholder="Enter Vendor Name"
                                                                required={true}
                                                                errorMessage="Vendor name is required"
                                                                maxlength={50}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className="form-group AI_btn_field">
                                                            <FormTextarea
                                                                name="comment"
                                                                id="comment"
                                                                label="Comment"
                                                                placeholder="Provide detail (optional)"
                                                            />
                                                            < OpenAIComponent
                                                                fieldName="comment"
                                                                formGroup={thirdPartyReportFormGroup}
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <div className="modal-footer la-app-modal-footer">
                                                {
                                                    thirdPartyReportType === "Add" ? (
                                                        <>
                                                            <button type="button" className="lm-button2" onClick={addReportPopupClose}>Cancel</button>
                                                            <button type="button" className="lm-button1" onClick={handleSubmitThirdPartyReport}>Save</button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button type="button" className="lm-button2 text-red" onClick={handleRemoveThirdPartyReport}>Remove</button>
                                                            <button type="button" className="lm-button1" onClick={handleSubmitThirdPartyReport}>Update</button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal>
                    {/* <!--add Third Party Report Popup / Modal --> */}

                </div >
            )
            }
        </>
    );
}



export default CreditMemo;