import Constants from "../../helpers/Constants";

export const handleFileUpload = async (event, year) => {

    const file = event.target.files[0];

    if (!file || file.type !== 'application/pdf') {
        console.error('Please upload a valid scanned PDF file.');
        return;
    }

    try {
        const text = await processScannedPDFWithFormRecognizer(file);

        const aiResponse = await getGeminiResponse(text);
        const formattedResponse = formatGeminiResponse(aiResponse);

        return formattedResponse;

    } catch (err) {
        console.error('Error processing file:', err);
    }
};

// Azure Form Recognizer
const processScannedPDFWithFormRecognizer = async (file) => {
    const formRecognizerUrl = `https://loanmantraocr.cognitiveservices.azure.com/formrecognizer/v2.1/layout/analyze`;

    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(formRecognizerUrl, {
            method: 'POST',
            headers: {
                'Ocp-Apim-Subscription-Key': Constants.formRecognizerAPIKey,
            },
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error: ${errorData.error.message}`);
        }

        const analysisStatusUrl = response.headers.get('operation-location');

        const finalResult = await pollForResult(analysisStatusUrl);

        const extractedText = finalResult.analyzeResult.readResults.map(page =>
            page.lines.map(line => line.text).join(' ')
        ).join(' ');

        return extractedText;

    } catch (error) {
        console.error('Error processing with Form Recognizer:', error);
        throw error;
    }
};

//Poll for Form Recognizer Analysis Result
const pollForResult = async (statusUrl) => {
    let isCompleted = false;
    let result = null;

    while (!isCompleted) {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Poll every 2 seconds
        const response = await fetch(statusUrl, {
            method: 'GET',
            headers: {
                'Ocp-Apim-Subscription-Key': Constants.formRecognizerAPIKey,
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error while polling status: ${errorData.error.message}`);
        }

        result = await response.json();

        if (result.status === 'succeeded' || result.status === 'failed') {
            isCompleted = true;
        }
    }

    return result;
};

//Getting response from Gemini AI
const getGeminiResponse = async (text) => {
    try {
        const response = await fetch(`https://generativelanguage.googleapis.com/v1beta/models/gemini-2.0-flash:generateContent?key=${Constants.geminiAPIKey}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contents: [
                    {
                        parts: [
                            {
                                text: `Extract the following financial values from the provided text and return them in a JSON format without any additional text or explanation alos fields names starts will Less: should return in negitve values:
                                - Revenue
                                - Gross Profit
                                - Net Income
                                - Depreciation
                                - Amortization
                                - Interest
                                - Cash
                                - Trade notes and A/R
                                - Less: allowance for bad debt	
                                - Inventories
                                - U.S. government obligations
                                - Tax-exempt securities
                                - Other current assets
                                - Loans to shareholders
                                - Mortgage and real estate loans
                                - Other investments
                                - Buildings and other depreciable assets
                                - Less: accumulated depreciation
                                - Depletable assets
                                - Less: accumulated depletion
                                - Land
                                - Intangible assets
                                - Less: accumulated amortization
                                - Other assets
                                - Accounts payable
                                - Mortgages, notes, bonds payable ( < 1 year)
                                - Other current liabilities
                                - All nonrecourse loans
                                - Mortgages, notes, bonds payable (>= 1 year)
                                - Other liabilities
                                - Loan from shareholders
                                - Capital stock
                                - Additional paid-in capital
                                - Retained earnings
                                - Adjustments to shareholders' equity
                                - Cost of treasury stock
                                - Loan from partners
                                - Partners Capital Account

                                Text: ${text}`,
                            },
                        ],
                    },
                ],
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData.error?.message || `Request failed with status ${response.status}`;
            console.error('Gemini API Error:', errorMessage);
            throw new Error(errorMessage);
        }

        const data = await response.json();
        if (data.candidates && data.candidates.length > 0) {
            const responseText = data.candidates[0].content.parts[0].text;
            const cleanJsonText = responseText.replace(/```json|```/g, '').trim();
            return JSON.parse(cleanJsonText);
        }

        throw new Error("No valid response from Gemini API.");
    } catch (error) {
        console.error('Error with Gemini API:', error);
        throw new Error('Failed to fetch data from Gemini: ' + error.message);
    }
};

//Formatting response for component
const formatGeminiResponse = (aiResponse) => {
    return {
        "Revenue": aiResponse.Revenue || '',
        "Gross Profit": aiResponse['Gross Profit'] || '',
        "Net Income": aiResponse['Net Income'] || '',
        "Depriciation": aiResponse.Depreciation || '',
        "Amortization": aiResponse.Amortization || '',
        "Intreset": aiResponse.Interest || '',

        "cash": aiResponse['Cash'] || '',
        "tradeNotesAndAR": aiResponse['Trade notes and A/R'] || '',
        "lessAllowanceForBadDebt": aiResponse['Less: allowance for bad debt	'] || '',
        "inventories": aiResponse['Inventories'] || '',
        "usGovernmentObligations": aiResponse['U.S. government obligations'] || '',
        "taxExemptSecurities": aiResponse['Tax-exempt securities'] || '',
        "otherCurrentAssets": aiResponse['Other current assets'] || '',
        "loansToShareholders": aiResponse['Loans to shareholders'] || '',
        "mortgageAndRrealEstateLoans": aiResponse['Mortgage and real estate loans'] || '',
        "otherInvestments": aiResponse['Other investments'] || '',
        "buildingsAndOtherDepreciableAssets": aiResponse['Buildings and other depreciable assets'] || '',
        "lessAccumulatedDepreciation": aiResponse['Less: accumulated depreciation'] || '',
        "depletableAssets": aiResponse['Depletable assets'] || '',
        "lessAccumulatedDepletion": aiResponse['Less: accumulated depletion'] || '',
        "land": aiResponse['Land'] || '',
        "intangibleAssets": aiResponse['Intangible assets'] || '',
        "lessAccumulatedAamortization": aiResponse['Less: accumulated amortization'] || '',
        "otherAssets": aiResponse['Other assets'] || '',
        
        "accountsPayable": aiResponse['Accounts payable'] || '',
        "otherCurrentLiabilities": aiResponse['Mortgages, notes, bonds payable ( < 1 year)'] || '',
        "Other current liabilities": aiResponse['Other current liabilities'] || '',
        "allNonrecourseLoans": aiResponse['All nonrecourse loans'] || '',
        "loanFromShareholders": aiResponse['Mortgages, notes, bonds payable (>= 1 year)'] || '',
        "mortgagesNotesBondsPayableMoreThanYear": aiResponse['Other liabilities'] || '',
        "otherLiabilities": aiResponse['Loan from shareholders'] || '',
        "capitalStock": aiResponse['Capital stock'] || '',
        "additionalPaidInCapital": aiResponse['Additional paid-in capital'] || '',
        "retainedEarnings": aiResponse['Retained earnings'] || '',
        "adjustmentsToShareholdersEquity": aiResponse["Adjustments to shareholders' equity"] || '',
        "lessCostOfTreasuryStock": aiResponse['Cost of treasury stock'] || '',
        "loanFromPartners": aiResponse['Loan from partners'] || '',
        "partnersCapitalAccount": aiResponse['Partners Capital Account'] || '',
    };
};
