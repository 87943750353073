import axios from "../helpers/axiosConfig";
import { getLocalStorageData } from "./storageData";

export const getAIRePhaseContent = async (contentToRephrase = "") => {
    try {
        const response = await axios.get(`/api/OpenAI/GetAIRephraseContent`, {
            params: { contentToRephrase, SessionId: getLocalStorageData("sessionId") }
        });
        if (response.data) {
            return response.data.resultObject?.replaceAll('"', '');
        } else {
            return response.error;
        }
    } catch (error) {
        console.error("Error:", error.message);
        throw error;
    }
}

export const getAIWriteForYou = async (loanId, loanType, ownerId, businessOwnerName, action) => {
    try {
        const response = await axios.get(`/api/OpenAI/GetAILoanSummary_BorrowingEntityCashFlow_GuarantorCashFlow`, {
            params: {
                LoanId : loanId,
                LoanType : loanType,
                OwnerId : ownerId,
                BusinessOwnerName : businessOwnerName,
                Action : action,
                SessionId: getLocalStorageData("sessionId")
            }
        });
        if (response.data) {
            return response.data.resultObject?.replaceAll('"', '');
        } else {
            return response.error;
        }
    } catch (error) {
        console.error("Error:", error.message);
        throw error;
    }
}
