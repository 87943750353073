import React from 'react';
import { FieldControl, Validators } from 'react-reactive-form';
import info_tooltip from '../assets/icons/Info-tooltip.svg';


const FormRadio = ({ name, options, label, required, errorMessage, onChange, tooltipEnabled, tooltipContent, tooltipPosition = "up", tooltipLength = "large" }) => (
    <div className="form-group">
        <div className="label pb-1">
            {label}
            {
                tooltipEnabled && (
                    <span className="lm-tooltip ml-1"
                        data-tooltip={tooltipContent}
                        data-tooltip-pos={tooltipPosition} data-tooltip-length={tooltipLength}> <img src={info_tooltip} alt="" />
                    </span>
                )
            }
        </div>
        <FieldControl
            name={name}
            render={({ handler, touched, hasError, value }) => {
                return (
                    <div>
                        {options.map(option => (
                            <div className="form-check form-check-inline" key={option.value}>
                                <input
                                    type="radio"
                                    className={`form-check-input ${touched && hasError('required') ? 'is-invalid' : ''}`}
                                    id={`${name}_${option.value}`}
                                    name={name}
                                    {...handler('radio')}
                                    value={option.value}
                                    checked={
                                        value === option.value ||
                                        ((value === null || value === '') && option.value === 'false') // Default "No"
                                    }
                                    onChange={(e) => {
                                        handler().onChange(e); // Call the internal handler first
                                        if (onChange) {
                                            onChange(e); // Then call the parent's onChange handler
                                        }
                                    }}
                                />
                                <label className="form-check-label" htmlFor={`${name}_${option.value}`}>{option.label}</label>
                            </div>
                        ))}
                        {touched && hasError('required') && (
                            <div className="error-message mt-2">{errorMessage || 'This field is required.'}</div>
                        )}
                    </div>
                );
            }}
            validators={required ? [Validators.required] : []}
        />
    </div>
);

export default FormRadio;
