import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';

import moment from "moment";

const isValidDate = (value) => {
    return moment(value, moment.ISO_8601, true).isValid();
};

const DynamicTable = ({
    data,
    dynamicTableDisplay,
    columns,
    changePageSize,
    pageSize,
    total,
    setList,
    setPageIndex,
    pageIndex,
    extraRow,
    entityList,
    selectedOption,
    hideRowsPerPage,
    hidePagination,
    selectAllRowsHeader
}) => {
    const pageCount = Math.ceil(total / pageSize);
    const [currentPageSize, setCurrentPageSize] = useState(pageSize);
    const [updownSort, setUpdownSort] = useState(1)
    const user = useSelector((state) => state.auth);

    const compareValues = (a, b, keyword, type) => {
        let aValue = a[keyword] || "";
        let bValue = b[keyword] || "";

        if (isValidDate(aValue) && isValidDate(bValue)) {
            aValue = moment(aValue).toDate();
            bValue = moment(bValue).toDate();
        } else if (!isNaN(aValue) && !isNaN(bValue)) {
            aValue = Number(aValue);
            bValue = Number(bValue);
        } else {
            aValue = aValue.toString().toLowerCase();
            bValue = bValue.toString().toLowerCase();
        }

        return type === "upwards" ? (aValue < bValue ? -1 : 1) : (aValue > bValue ? -1 : 1);
    };
    
    const handlePageClick = (event) => {
        if (!event.isBreak) {
            if (event.nextSelectedPage || event.nextSelectedPage === 0) {
                setPageIndex(event.nextSelectedPage + 1);
            }
        }
    };

    const handleSort = (keyword, type) => {
        let sortedData = [...data].sort((a, b) => compareValues(a, b, keyword, type));
        setList(sortedData);
    };

    const updownSortHandler = (head) => {
        const newSort = updownSort + 1;
        if (newSort % 2 === 0) {
            handleSort(head.value, "downwards");
        } else {
            handleSort(head.value, "upwards");
        }
        setUpdownSort(newSort);
        return newSort;
    };

    return (
        <table className="lm-grid-table" id="loanAppTable">
            <thead>
                <tr>
                    {columns.map((head, index) => (
                        <th key={index + new Date().getTime()} hidden={head.visible ? !head.visible.includes(user.user.roleName) : false}>
                            <div onClick={() => updownSortHandler(head)} className={`d-flex pointer-cursor 
                                    ${head.name !== "Loan Amount" ? "" : "col-head-right"} 
                                    ${head.name === "ACTIONS" ? "justify-content-end" : ""}`}
                            >
                                {
                                    head.name === "Select All" ? selectAllRowsHeader() : head.name
                                }
                                <span className="sort-arrow" hidden={!head.sortable}>
                                    <div className="lm-angle-up-icon" onClick={() => handleSort(head.value, "upwards")}></div>
                                    <div className="lm-angle-down-icon" onClick={() => handleSort(head.value, "downwards")}></div>
                                </span>
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {extraRow ? extraRow(entityList, selectedOption) : ""}
                {data.map((row, index) => dynamicTableDisplay(row, index))}
            </tbody>
            {!hidePagination && (
                <tfoot>
                    <tr>
                        <td colSpan="9">
                            <div className="pagination pagination-r-tbl">
                                <div className="d-flex">
                                    <ReactPaginate
                                        nextLabel=">"
                                        onClick={handlePageClick}
                                        disableInitialCallback={true}
                                        pageRangeDisplayed={pageSize}
                                        pageCount={pageCount}
                                        previousLabel="<"
                                        initialPage={pageIndex - 1}
                                        className="d-flex"
                                        pageClassName="mx-2 text-primary"
                                        pageLinkClassName="text-secondary"
                                        activeClassName="active-page"
                                    />
                                </div>
                                {!hideRowsPerPage && (
                                    <div>
                                        <input
                                            className="text-center page-number-box"
                                            type="number"
                                            min="0"
                                            max="100"
                                            onChange={(e) => {
                                                const size = Math.max(0, Math.min(100, Number(e.target.value)));
                                                setPageIndex(1);
                                                setCurrentPageSize(size);
                                            }}
                                            onBlur={(e) => {
                                                const size = Math.max(0, Math.min(100, Number(e.target.value)));
                                                setPageIndex(1);
                                                changePageSize(size === 0 ? 1 : size);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    const size = Math.max(0, Math.min(100, Number(e.target.value)));
                                                    setPageIndex(1);
                                                    changePageSize(size === 0 ? 1 : size);
                                                }
                                            }}
                                            value={currentPageSize}
                                        />
                                        <span className="text-light ml-2">Rows per page</span>
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                </tfoot>
            )}
        </table>
    );
};

export default DynamicTable;
